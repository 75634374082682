<template>
  <div class="head-nav">
    <!-- 顶部导航栏 -->

    <div>
      <img src="../../public/logo.png" style="height: 33px" />
    </div>
    <div class="Navigation">
      <div
        :class="[2 == navnum ? 'navignum' : '']"
        index="2"
        class="hvr-shutter-out-horizontal"
        @mouseenter="yiru(2)"
        @click="handleSelect(2)"
        style="color: black; background: #ffff"
      >
        公司介绍
      </div>

      <div index="z" disabled style="cursor: pointer">┊</div>
      <div
        :class="[navnum == 1 ? 'navignum' : '']"
        index="1"
        class="hvr-shutter-out-horizontal"
        @mouseenter="yiru(1)"
        @click="handleSelect(1)"
        style="color: black; background: #ffff"
      >
        独家优势
      </div>
      <div index="z" disabled style="cursor: pointer">┊</div>
      <div
        :class="[navnum == 7 ? 'navignum' : '']"
        index="7"
        class="hvr-shutter-out-horizontal"
        @mouseenter="yiru(7)"
        @click="handleSelect(7)"
        style="color: black; background: #ffff"
      >
        淘宝店铺
      </div>
      <div index="z" disabled style="cursor: pointer">┊</div>
      <div
        :class="[navnum == 8 ? 'navignum' : '']"
        index="8"
        class="hvr-shutter-out-horizontal"
        @mouseenter="yiru(8)"
        @click="handleSelect(8)"
        style="color: black; background: #ffff"
      >
        卖家秀
      </div>

      <div index="z" disabled style="cursor: pointer">┊</div>
      <div
        :class="[navnum == 5 ? 'navignum' : '']"
        index="5"
        class="hvr-shutter-out-horizontal"
        @mouseenter="yiru(5)"
        @click="handleSelect(5)"
        style="color: black; background: #ffff"
      >
        行业资讯
      </div>
      <div index="x" disabled style="cursor: pointer">┊</div>
      <!--          <el-menu-item index="3">卖金流程</el-menu-item>-->
      <!--          <el-menu-item index="4">安全保障</el-menu-item>-->

      <div
        :class="[navnum == 6 ? 'navignum' : '']"
        index="6"
        class="hvr-shutter-out-horizontal"
        @mouseenter="yiru(6)"
        @click="handleSelect(6)"
        style="color: black; background: #ffff"
      >
        合作伙伴
      </div>
    </div>
    <div>
      <div
        style="
          color: #75737e;
          align: right;
          font-size: 19px;
          margin-left: 72px;
          width: 158px;

          display: flex;
          align-items: center;
        "
      >
        <div>
          <img
            style="width: 18px; height: 18px"
            src="https://oss.duijh.cn/upload/test/wed945463215nujj.png"
            alt=""
          />
        </div>
        <div style="margin-top: 2px; margin-left: 8px; font-size: 15px">
          400-009-3003
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "head-nav",
  data() {
    return {
      navnum: 2,
      activeIndex: "2",
      activeIndex2: "2",
      top: {
        1: "dujia",
        5: "hangye",
        6: "hezuo",
        4: "anquan",
        3: "maijin",
        2: "gongsi",
        8: "xiu",
      },
    };
  },

  methods: {
    yiru(key) {
      this.navnum = key;
    },
    handleSelect(key, keyPath) {
      console.log(document.getElementById(this.top[key]));
      console.log(key);
      if (!document.getElementById(this.top[key]) == null) {
        document.getElementById(this.top[key]).scrollIntoView(falser, {
          behavior: "smooth",
        });
      }
      if (key == "7") {
        window.open(
          "https://shop419046451.taobao.com/?spm=2013.1.0.0.9da7249fBMlPnw"
        );
        return;
      }
      console.log(this.$route.path);
      if (this.$route.path == "/") {
        document.getElementById(this.top[key]).scrollIntoView({
          behavior: "smooth",
        });
      } else {
        this.$router.push({ name: "Home", params: { id: key } });
        // window.location.href = "http://localhost:8887/?#/?id="+key
      }
    },
  },
};
</script>


<style scoped>
.navignum {
  position: relative;
}
.Navigation {
  width: 48.97%;
  display: flex;

  justify-content: space-between;
}
.head-nav {
  width: 87.14%;
  display: flex;
  height: 91px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  overflow: hidden;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 999;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 0px;
}
.navignum::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 8px;
  right: 0;
  height: 5px;
  width: 45px;
  margin: 0 auto;
  background: #c77a0e !important;
  top: 20px;
}



.el-menu {
  padding-left: 0;
}
.el-menu.el-menu--horizontal {
  border-bottom: solid 0px;
}
.element.style {
  color: aliceblue;
}
body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.el-menu-item {
  font-size: 16px;
}
.el-menu--horizontal > .el-menu-item {
  color: #2f2d3a;
}
.hvr-shutter-out-horizontal:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 8px;
  right: 0;
  height: 5px;
  width: 45px;
  margin: 0 auto;
  background: #c77a0e !important;
  top: 20px;
}
</style>
