<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>



export default {
  name: 'app',
  components: {


  }
}
</script>

<style>
.el-carousel__indicators--horizontal{
  bottom: 68px !important;
}
.el-carousel__button{
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
}

.el-menu-item{
  /* border-right: 1px solid #000 !important; */
}
#app {

}
</style>
