<template>

    <div class="home" style="overflow-x: hidden">
    <div style="position: relative">
      <Sid ref="sid" />
      <div id="hearder-width">
        <Hear />
      </div>
      <Car />

      <div class="header">
        <el-row>
          <el-col
            :span="12"
            style="position: absolute; top: 40%; margin-left: 3%"
          >
            <span class="ding">实时金价</span>
            <span style="margin-left: 31px" class="jiage">{{ jin }}</span>
            <span class="biaojia">元/克</span>
          </el-col>
          <el-col :span="12" style="margin-left: 50%; margin-top: 5%">
            <el-button
              type="info"
              round
              class="buttonz"
              style="background: #c77a0e; border: 1px solid #c77a0e"
              @click="dianwo()"
            >
              <span class="buzt">免费估价</span></el-button
            >
          </el-col>
        </el-row>
      </div>
    </div>
    <div style="height: 200px" id="gongsi"></div>
    <!-- 公司介绍 -->
    <div class="exclusive">
      <!-- 左侧滑动布局 -->
      <div class="leftassociative">
        <div
          class="animate__animated"
          :class="{ animate__fadeInLeft: aboutUS }"
          ref="aboutUS"
        >
          <img
            class="Introduction"
            src="https://oss.duijh.cn/upload/test/tjp553059039wtrj.png"
          />
          <div class="eating">ABOUT US</div>
        </div>
        <div
          class="company animate__animated"
          :class="{ animate__fadeInLeft: company }"
          ref="company"
        >
          {{ aboutvalue }}

          <span class="desktop" @click="gongsi">了解更多</span>
        </div>
        <!-- 底部横向排列的button  :class="{ animate__fadeInLeft: company }"-->
        <div class="groups">
          <div
            :class="[
              1 == aboutnum
                ? 'field_default_value'
                : 'field_default_value_state',
            ]"
            @click="clickabout(1)"
          >
            企业介绍/CULTURE
          </div>
          <div
            :class="[
              2 == aboutnum
                ? 'field_default_value'
                : 'field_default_value_state',
            ]"
            @click="clickabout(2)"
          >
            企业资质/quality
          </div>
          <div
            :class="[
              3 == aboutnum
                ? 'field_default_value'
                : 'field_default_value_state',
            ]"
            @click="clickabout(3)"
          >
            会员资质/member
          </div>
        </div>
      </div>
      <!-- 右侧视频显示 -->
      <div class="rightassociative">
        <myvideo />
      </div>
    </div>
    <div></div>
    <!--买金流程-->
    <div id="maijin" class="sellyourgoldsyracuse" ref="sellerimg">
      <img
        src="https://oss.duijh.cn/upload/test/udq502698330iqes.pic_hd.jpg"
        class="backgroundvar"
      />
      <div class="sellyourimg animate__animated"
           :class="{ animate__fadeInLeft: sellerimg }"
      >
        <img
          src="https://oss.duijh.cn/upload/test/kac091110396vwxn.pic.jpg"
          alt=""
        />
      </div>
      <div class="ofbackgroundvar">
        <img
          src="https://oss.duijh.cn/upload/test/ngy927673341fdbt.png"
          alt=""
          class="ofbackgroundvarimg"
        />
        <div class="profitconfidential">
          <div
            @mouseover="movein(1)"
            @mouseleave="movein(0)"
       
            class="profitconfidentialdiv hvr-grow"
          >
            <img
              v-if="sellgoid == 1"
              src="https://oss.duijh.cn/upload/test/wqi766220386kfui.png"
              alt=""
              class="iconnumber"
            />
            <img
              v-else
              class="iconnumber"
              src="https://oss.duijh.cn/upload/test/wbf587802041ebft.png"
              alt=""
            />

            <div style="position: relative" class="Subpicture-box">
              <div :class="[sellgoid == 1 ? 'animated animate__bounce' : '']">
                <img
                  src="https://oss.duijh.cn/upload/test/osk113106477kjpt.png"
                  class="Subpicture"
                />
              </div>

              <img
                class="shadow"
                src="https://oss.duijh.cn/upload/test/nju680864372xbep.png"
                alt=""
              />
              <!-- <div class="shadow"></div> -->
            </div>
            <div class="sellingtitle">线上下单</div>
            <div class="sellingvalue">微信搜索</div>
            <div class="sellingvaluetwo" style="margin-right: 11px">
              「兑鑫行」小程序
            </div>
          </div>
          <img
            class="profitimg"
            src="https://oss.duijh.cn/upload/test/hll406166835dczk.png"
            alt=""
          />
          <div
            class="profitconfidentialdiv hvr-grow"
            @mouseover="movein(2)"
            @mouseleave="movein(0)"
          >
            <img
              v-if="sellgoid == 2"
              src="https://oss.duijh.cn/upload/test/akc947863849jgbj.png"
              alt=""
              class="iconnumber"
            />
            <img
              v-else
              class="iconnumber"
              src="https://oss.duijh.cn/upload/test/yje867890775esdv.png"
              alt=""
            />

            <div style="position: relative">
              <div :class="[sellgoid == 2 ? 'animated animate__bounce' : '']">
                <img
                  src="https://oss.duijh.cn/upload/test/opm930970338umgy.png"
                  class="Subpicture"
                />
              </div>
              <img
                class="shadow"
                src="https://oss.duijh.cn/upload/test/nju680864372xbep.png"
                alt=""
              />
            </div>
            <div class="sellingtitle">顺丰上门</div>
            <div class="sellingvalue">1小时内上门取件</div>
            <div class="sellingvaluetwo">全额保价直达质检</div>
          </div>
          <img
            class="profitimg"
            src="https://oss.duijh.cn/upload/test/hll406166835dczk.png"
            alt=""
          />
          <div
           
            @mouseover="movein(3)"
            @mouseleave="movein(0)"
            class="profitconfidentialdiv hvr-grow"
          >
            <img
              v-if="sellgoid == 3"
              src="https://oss.duijh.cn/upload/test/eze723633684alcs.png"
              alt=""
              class="iconnumber"
            />
            <img
              v-else
              class="iconnumber"
              src="https://oss.duijh.cn/upload/test/kvc647154559yuyh.png"
              alt=""
            />

            <div style="position: relative">
              <div :class="[sellgoid == 3 ? 'animated animate__bounce' : '']">
                <img
                  src="https://oss.duijh.cn/upload/test/hny933856423zfyz.png"
                  class="Subpicture"
                />
              </div>
              <img
                class="shadow"
                src="https://oss.duijh.cn/upload/test/nju680864372xbep.png"
                alt=""
              />
            </div>
            <div class="sellingtitle">质检打款</div>
            <div class="sellingvalue">小程序接收质检报告</div>
            <div class="sellingvaluetwo">确认后坐等收款</div>
          </div>
        </div>
      </div>
    </div>
    <div id="dujia"></div>
    <!-- 6大优势 -->
    <div class="advantage" ref="advant">
      <div class="animate__animated" :class="{ animate__fadeInLeft: advant }">
      <img
        src="https://oss.duijh.cn/upload/test/lxk845913075vpio.png"
        class="advantagetite"
      />
      <div class="advantageEnglish">EXCLUSIVE ADVANTAGE</div>
      </div>
      <div
        class="advantageImgList"
        @mouseleave="advallseleavefun()"
        @mouseover="advallseleavefun()"
      >
        <div
          @mouseover="advfun(1)"
          ref="adv1"
          @mouseleave="advseleavefun(1)"
          class="exclusively"
          src="https://oss.duijh.cn/upload/test/lyp116400300awdi.png"
          alt=""
        >
          <p style="display: none">
            <span> \</span> <br />
            国 <br />
            企 <br />
            背 <br />
            景 <br />
          </p>
        </div>
        <div
          ref="adv2"
          @mouseover="advfun(2)"
          @mouseleave="advseleavefun(2)"
          src="https://oss.duijh.cn/upload/test/moh252043553aazm.png"
          class="exclusively"
          alt=""
        >
          <p>
            <span> \</span> <br />
            高 <br />
            价 <br />
            回 <br />
            收 <br />
          </p>
        </div>
        <div
          ref="adv3"
          @mouseover="advfun(3)"
          @mouseleave="advseleavefun(3)"
          src="https://oss.duijh.cn/upload/test/ocx831089163yihb.png"
          class="exclusively"
          alt=""
        >
          <p>
            <span> \</span> <br />
            国 <br />
            级 <br />
            质 <br />
            检 <br />
          </p>
        </div>

        <div
          ref="adv4"
          @mouseover="advfun(4)"
          @mouseleave="advseleavefun(4)"
          src="https://oss.duijh.cn/upload/test/tmp374138348kmtm.png"
          class="exclusively"
          alt=""
        >
          <p>
            <span> \</span> <br />
            免 <br />
            费 <br />
            估 <br />
            价 <br />
          </p>
        </div>

        <div
          ref="adv5"
          @mouseover="advfun(5)"
          @mouseleave="advseleavefun(5)"
          src="https://oss.duijh.cn/upload/test/lhz335072798obwz.png"
          class="exclusively"
          alt=""
        >
          <p>
            <span> \</span> <br />
            去 <br />
            中 <br />
            间 <br />
            商 <br />
          </p>
        </div>
        <div
          ref="adv6"
          @mouseover="advfun(6)"
          @mouseleave="advseleavefun(6)"
          src="https://oss.duijh.cn/upload/test/tei267945198wdjh.png"
          class="exclusively"
          alt=""
        >
          <p>
            <span> \</span> <br />
            专 <br />
            业 <br />
            客 <br />
            服 <br />
          </p>
        </div>
      </div>
    </div>

    <!--4大保障-->
    <div id="anquan" class="SecurityBundle" ref="Security">
      <div  class="animate__animated" :class="{ animate__fadeInLeft: Security }">
      <img
        src="https://oss.duijh.cn/upload/test/mls257050678udxp.png"
        alt=""
        class="securitybundleimg"
      />

      <div class="securitybundletite">SECURITY</div>
      </div>
      <div class="securityBundlevalue">
        <div
          @mouseover="Securitseouver(1)"
          @mouseleave="Securitleave(1)"
          style="position: relative"
          class="securitall"
        >
          <img
            class="securityBundlevalueimg"
            src="https://oss.duijh.cn/upload/test/lxu160574753bmdf.png"
            ref="securitimgenlarge1"
          />
          <div ref="securitimg1" class="securMask">
            <div class="masktite">国企服务</div>
            <div class="maskvalue">安全又高价的回收平台</div>
            <div class="maskdecorate" ref="masksecuritimg1"></div>
          </div>
        </div>
        <div
          @mouseover="Securitseouver(2)"
          @mouseleave="Securitleave(2)"
          style="position: relative"
          class="securitall"
        >
          <img
            class="securityBundlevalueimg"
            src="https://oss.duijh.cn/upload/test/iem872663428rixj.png"
            alt=""
          />
          <div ref="securitimg2" class="securMask">
            <div class="masktite">运输保障</div>
            <div class="maskvalue">顺丰速运·提供顶尖运输服务</div>
            <div class="maskdecorate" ref="masksecuritimg2"></div>
          </div>
        </div>
        <div
          @mouseover="Securitseouver(3)"
          @mouseleave="Securitleave(3)"
          style="position: relative"
          class="securitall"
        >
          <img
            class="securityBundlevalueimg"
            src="https://oss.duijh.cn/upload/test/yjw341849191geqd.png"
            alt=""
          />
          <div ref="securitimg3" class="securMask">
            <div class="masktite">全额赔付</div>
            <div class="maskvalue">中国太平·全额赔付保险</div>
            <div class="maskdecorate" ref="masksecuritimg3"></div>
          </div>
        </div>

        <div
          @mouseover="Securitseouver(4)"
          @mouseleave="Securitleave(4)"
          style="position: relative"
          class="securitall"
        >
          <img
            class="securityBundlevalueimg"
            src="https://oss.duijh.cn/upload/test/bxp842215168fnrr.png"
            alt=""
          />
          <div ref="securitimg4" class="securMask">
            <div class="masktite">网络安全</div>
            <div class="maskvalue">法治保障·联网京公网安备</div>
            <div class="maskdecorate" ref="masksecuritimg4"></div>
          </div>
        </div>
      </div>
    </div>
    <div id="xiu"></div>
    <!-- 卖家秀 -->
    <div class="SellerController">

      <div class="selleruserinfo animate__animated" 
       :class="{ animate__fadeInLeft: seller }"
      >
        <img
          class="SellerControllerimg"
          src="https://oss.duijh.cn/upload/test/cud542061724wzns.png"
        />
        <div class="SellerControllervalue" style="margin-top: 8px">
          SELLER SHOW
        </div>
      </div>
      <div class="swiper-container" ref="swip">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in xiu" :key="index">
            <img :src="item.imgpathone" class="slideshowwrapper" alt="" />
            <div class="slideshowvalue">
              <div style="overflow: hidden">
                <img class="userloadPortrait" :src="item.imgpathtwo" alt="" />
                <span class="userblock"> {{ item.connectforn }}</span>
                <span class="usertime">{{ item.connectthree }}</span>
              </div>
              <div class="userinfo">
                <span class="userinfotite"
                  >{{ item.connectone }}
                  <span class="userinfovlue">
                    {{ item.connecttwo }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="hangye"></div>
    <!--    行业资讯 -->
    <div class="information">
      <div
      class="animate__animated" 
      :class="{ animate__fadeInLeft: infoma }"
      >
      <img
        class="infomationimg"
        src="https://oss.duijh.cn/upload/test/kcc578440662pjyt.png"
        alt=""
      />
      <div class="infomationvalue" style="margin-top: 8px">
        INDUSTRY INFORMATION
      </div>
      </div>
      <div class="infomationinfolist">
        <div class="infomationblock">
          <img :src="info[0].imgpathone" alt="" />
          <div
            ref="Industry"
            class="animate__animated"
            :class="{ animate__fadeInLeft: Industry }"
          >
            <div class="infoamtionblocktite">{{ info[0].headline }}</div>
            <div class="infoamtiontime">{{ info[0].createTime }}</div>
          </div>
          <div
            class="infoamtionbody animate__animated"
            :class="{ animate__fadeInLeft: industinfovalue }"
          >
            {{ info[0].connectone }}
          </div>
          <router-link :to="{ path: '/about', query: { id: 0 } }">
            <div class="infoamtionbottom">更多</div>
          </router-link>
        </div>
        <div class="infomationblock" ref="Industryright">
          <img :src="info[1].imgpathone" alt="" />
          <div
            class="animate__animated"
            :class="{ animate__fadeInRight: Industryright }"
          >
            <div class="infoamtionblocktite">{{ info[1].headline }}</div>
            <div class="infoamtiontime">{{ info[1].createTime }}</div>
          </div>
          <div
            class="infoamtionbody animate__animated"
            :class="{ animate__fadeInRight: industinfovalue }"
          >
            {{ info[1].connectone }}
          </div>
          <router-link :to="{ path: '/about', query: { id: 1 } }">
            <div class="infoamtionbottom" id="hezuo">更多</div>
          </router-link>
        </div>
      </div>
    </div>

    <hr />

    <!-- <span class="hangye_en" id="hangye" style="width: 278px"
      >INDUSTRY INFORMATION</span
    >
    <p
      style="
        text-align: center;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #2f2d3a;
        margin-top: 29px;
      "
    >
      行业资讯
    </p>
    <p
      style="
        width: 45px;
        height: 2px;
        background: #2f2d3a;
        margin-left: 48%;
        margin-top: 14px;
      "
    ></p>
    <div style="height: 31px"></div> -->
    <!--    行业资讯中的三个-->
    <!-- <div style="width: 72%; margin-left: 18%">
      <div class="hangye_tap" style="font-size: 0px">
        <p class="hangye_header" style="margin-left: 20px; margin-top: 20px">
          {{ info[0].headline }}
        </p>
        <p
          align="left"
          class="hangye_yq"
          style="
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            margin-right: 15px;
            margin-left: 20px;
            margin-top: 20px;
          "
        >

          {{ info[0].connectone }}
        </p>

        <div style="height: 50px"></div>
        <p class="hangye_fg"></p>
        <div style="height: 4px; width: 20px"></div>

        <router-link :to="{ path: '/about', query: { id: 0 } }">
          <span
            ref="ab"
            class="hangye_xx"
            @mouseleave="cca('ab')"
            @mouseover="ca('ab')"
            >查看详情</span
          >
          <i class="el-icon-right" style="margin-left: 65%"></i>
        </router-link>

        <div style="height: 12px"></div>
        <img style="width: 100%; height: 100%" :src="info[0].imgpathone" />
      </div>
      <div style="width: 63px; display: inline-block"></div>
      <div class="hangye_tap" style="font-size: 0px">
        <p class="hangye_header" style="margin-left: 20px; margin-top: 20px">
          {{ info[1].headline }}
        </p>
        <p
          align="left"
          class="hangye_yq"
          style="
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            margin-right: 15px;
            margin-left: 20px;
            margin-top: 20px;
          "
        >
          {{ info[1].connectone }}
        </p>
        <div style="height: 50px"></div>

        <p class="hangye_fg"></p>
        <div style="height: 4px; width: 20px"></div>

        <router-link :to="{ path: '/about', query: { id: 1 } }">
          <span
            class="hangye_xx"
            @mouseleave="cca('ac')"
            @mouseover="ca('ac')"
            ref="ac"
            >查看详情</span
          >
          <i class="el-icon-right" style="margin-left: 65%"></i>
        </router-link>

        <div style="height: 10px"></div>
        <img style="width: 100%" :src="info[1].imgpathone" />
      </div>
      <div style="width: 63px; display: inline-block"></div>
      <div class="hangye_tap" style="font-size: 0px">
        <p class="hangye_header" style="margin-left: 20px; margin-top: 20px">
          {{ info[2].headline }}
        </p>
        <p
          align="left"
          class="hangye_yq"
          style="
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            margin-right: 15px;
            margin-left: 20px;
            margin-top: 20px;
          "
        >
          {{ info[2].connectone }}
        </p>
        <div style="height: 50px"></div>

        <p class="hangye_fg"></p>
        <div style="height: 4px; width: 20px"></div>
        <router-link :to="{ path: '/about', query: { id: 2 } }">
          <span
            class="hangye_xx"
            @mouseleave="cca('ad')"
            @mouseover="ca('ad')"
            ref="ad"
            >查看详情</span
          >
          <i class="el-icon-right" style="margin-left: 65%"></i>
        </router-link>
        <div style="height: 10px"></div>
        <img style="width: 100%" :src="info[2].imgpathone" />
      </div>
    </div> -->
    <!--    合作机构-->
    <div class="partners">
      <div
      class="animate__animated" 
      :class="{ animate__fadeInLeft: parten }"
      >
      <img
        class="partnersimg"
        src="https://oss.duijh.cn/upload/test/tii164028172eoaq.png"
        alt=""
      />
      <div class="partnersvlaue">COOPERATIVE PARTNER</div>
      </div>
      <img
        class="partnersvlaueimg"
        src="https://oss.duijh.cn/upload/test/bsm808405699ftqe.png"
        alt=""
      />
    </div>
    <!--底部-->
    <Bottom />
    <!--    背景层-->

    <div class="zhezhao" ref="zhezhao" style="display: none; z-index: 300">
      <div class="tankuang" ref="tankuang">
        <div id="header">
          <div class="duiding">
            <!--          图片-->
            <img class="tup" src="../../public/logo1.png" />
          </div>
          <div
            id="header-right"
            class="guan"
            style="background: #c77a0e"
            @click="hidder()"
          >
            x
          </div>
          <!--          底部主菜单-->

          <div class="ziti">我要估价</div>

          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
          >
            <el-form-item
              prop="tabletype"
              style="margin-left: 44px; margin-bottom: 30px"
            >
              <el-dropdown
                trigger="click"
                @command="handleCommand"
                style="width: 79%"
              >
                <span class="el-dropdown-link">
                  <el-input
                    style="width: 94%"
                    placeholder="类型"
                    readonly="readonly"
                    :value="ruleForm.tabletype"
                  />

                  <i
                    class="el-icon-caret-bottom el-icon--right"
                    style="
                      margin-left: 0px;
                      position: absolute;
                      right: 36px;
                      margin-right: 0px;
                      top: 12px;
                    "
                  ></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="金条">金条</el-dropdown-item>
                  <el-dropdown-item command="金饰">金饰</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <span style="color: #c71b1a">*</span>
            </el-form-item>

            <el-form-item
              prop="tableke"
              style="margin-left: 44px; margin-bottom: 30px"
            >
              <el-input
                style="width: 75%"
                placeholder="克数"
                v-model="ruleForm.tableke"
              />
              <span style="color: #c71b1a; margin-left: 18px">*</span>
            </el-form-item>

            <el-form-item
              prop="ipone"
              style="margin-left: 44px; margin-bottom: 30px"
            >
              <el-input
                style="width: 75%"
                placeholder="手机"
                v-model="ruleForm.ipone"
              />
              <span style="color: #c71b1a; margin-left: 18px">*</span>
            </el-form-item>

            <el-form-item style="margin-left: 44px; margin-bottom: 30px">
              <div
                @click="xiala()"
                v-if="cs"
                style="display: inline-block; width: 75%"
              >
                <el-input
                  placeholder="城市"
                  readonly="readonly"
                  :value="cheng + shi"
                />
              </div>
              <i
                @click="xiala()"
                class="el-icon-caret-bottom"
                v-if="cs"
                style="margin-right: 0px; position: relative; right: 30px"
              ></i>
              <span v-if="cs" style="color: #c71b1a; margin-left: 5px">*</span>
              <div
                class="chengshi"
                style="height: 200px; display: block"
                v-if="!cs"
                id="buitngh"
              >
                <div style="width: 100%; height: 10px"></div>
                <i
                  class="el-icon-caret-bottom"
                  style="margin-right: 0px; position: relative; left: 87%"
                  @click="xiala()"
                ></i>

                <span class="cs">城市</span>

                <span class="xe" @click="xiala()">{{ cheng }}{{ shi }}</span>
                <div class="x">请选择</div>
                <div style="height: 50%; overflow-y: auto">
                  <div
                    v-for="(item, index) in chen"
                    v-bind:key="index"
                    style="margin-top: 10px"
                  >
                    <div
                      style="
                        margin: 0 auto;
                        display: block;
                        height: 100%;
                        width: 100%;
                      "
                    >
                      <button
                        type="button"
                        @click="biao(item[0])"
                        :class="item[0] == isShow ? 'active' : 'ko'"
                        v-if="item[0] != undefined"
                      >
                        {{ item[0] }}
                      </button>
                      <button
                        type="button"
                        @click="biao(item[1])"
                        :class="item[1] == isShow ? 'active' : 'ko'"
                        v-if="item[1] != undefined"
                      >
                        {{ item[1] }}
                      </button>
                      <button
                        type="button"
                        @click="biao(item[2])"
                        :class="item[2] == isShow ? 'active' : 'ko'"
                        v-if="item[2] != undefined"
                      >
                        {{ item[2] }}
                      </button>
                      <!--                  <button  @click="biao(item[3])" :class="item[3]==isShow ? 'active' : 'ko'" v-if="item[3] != undefined">{{ item[3] }}</button>-->
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>

            <el-button
              type="info"
              round
              v-if="cs"
              style="
                display: block;
                margin-left: 41px;
                width: 368px;
                height: 50px;
                border-radius: 8px;
                background: #c77a0e;
                border: 1px solid #c77a0e;
              "
              @click="submit('ruleForm')"
            >
              <span>提交</span></el-button
            >
          </el-form>
        </div>
      </div>
    </div>

    <!--  弹框 -->
    <div class="zhezhao" ref="zhe" style="display: none; z-index: 300">
      <div class="tankuang">
        <div>
          <div class="duiding">
            <!--          图片-->
            <img class="tup" src="../../public/logo1.png" />
          </div>
          <div class="guan" style="background: #2f2d3a" @click="hidder()">
            x
          </div>
          <!--          底部主菜单-->
          <div style="display: flex; flex-flow: column; align-items: center">
            <div class="zit" style="margin-top: 10%">提交成功</div>

            <span style="width: 87%; margin-top: 10px"
              >您已成功提交估价信息，专属客服会尽快与您取得联系，请您保持电话畅通。</span
            >

            <el-button
              type="info"
              round
              v-if="cs"
              style="
                display: block;
                width: 87%;
                height: 50px;
                border-radius: 8px;
                margin-top: 10%;
              "
              @click="quer"
            >
              <span>确定</span></el-button
            >
            <span style="margin-top: 10%; width: 87%" class="wen">
              温馨提示*
              <div>
                您可点击页面右侧<a
                  href="https://085670.kefu.easemob.com/webim/im.html?configId=97eba1e1-153c-4734-b6e4-5b3b3d1ae9a2"
                  style="color: #237bfe"
                  >【在线客服】</a
                >或是添加右侧【微信客服】，享受 更多服务。
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- <script type='text/javascript' src='https://webchat.7moor.com/javascripts/7moorInit.js?accessId=8bb96590-4dfa-11ee-94a2-c33ec2b31024&autoShow=true&language=ZHCN' async='async'/> -->

<script>
import Hear from "../components/Heard.vue";
import Car from "../components/Carousel.vue";
import Sid from "../components/Side.vue";
import Bottom from "../components/bottom.vue";
import myvideo from "../components/myvideo.vue";
import { square } from "../urils/ssj.js";
import Swiper from "swiper";
import "animate.css";
import { isElementNotInViewport } from "../utils/domview";
import "hover.css";




export default {
  name: "home",
  components: {
    Hear,
    Car,
    Bottom,
    Sid,
    myvideo,
  },
  data() {
    return {
      parten:false,
      infoma:false,
      seller:false,
      Security:false,
      advant:false,
      sellgoid: "",
      xiu: [],
      advnum: 1,
      industinfovalue: false,
      sellerimg: false,
      viewnumber: 0,
      aboutvalue:
        "兑鑫行是北京市国资委监管一级企业“北京工美集团”旗下品牌定点黄金回收、以旧换新平台。上线至今，积极响应政府的“循环经济”号召，专注于黄金产业链效率提升，切实解决消费者“买金容易，卖金难，换新难’的痛点。并荣获高新技术企业证书，并与京东...",
      aboutnum: 1,
      company: false,
      aboutUS: false,
      Industry: false,
      Industryright: false,
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        // Some Swiper option/callback...
      },
      sellerlist: [
        {
          id: "7",
          img: "https://oss.duijh.cn/upload/test/hlj846669407czhy.png",
          avatars: "https://oss.duijh.cn/upload/test/etm856743601kysm.png",
          username: "测试",
          creadtime: "2020-09-23",
        },
        {
          id: "6",
          img: "https://oss.duijh.cn/upload/test/hlj846669407czhy.png",
          avatars: "https://oss.duijh.cn/upload/test/etm856743601kysm.png",
          username: "测试",
          creadtime: "2020-09-23",
        },
        {
          id: "5",
          img: "https://oss.duijh.cn/upload/test/hlj846669407czhy.png",
          avatars: "https://oss.duijh.cn/upload/test/etm856743601kysm.png",
          username: "测试",
          creadtime: "2020-09-23",
        },
        {
          id: "4",
          img: "https://oss.duijh.cn/upload/test/hlj846669407czhy.png",
          avatars: "https://oss.duijh.cn/upload/test/etm856743601kysm.png",
          username: "测试",
          creadtime: "2020-09-23",
        },
        {
          id: "3",
          img: "https://oss.duijh.cn/upload/test/hlj846669407czhy.png",
          avatars: "https://oss.duijh.cn/upload/test/etm856743601kysm.png",
          username: "测试",
          creadtime: "2020-09-23",
        },
        {
          id: "2",
          img: "https://oss.duijh.cn/upload/test/hlj846669407czhy.png",
          avatars: "https://oss.duijh.cn/upload/test/etm856743601kysm.png",
          username: "测试",
          creadtime: "2020-09-23",
        },
        {
          id: "1",
          img: "https://oss.duijh.cn/upload/test/hlj846669407czhy.png",
          avatars: "https://oss.duijh.cn/upload/test/etm856743601kysm.png",
          username: "测试",
          creadtime: "2020-09-23",
        },
      ],
      imglist: [
        "https://oss.duijh.cn/upload/test/lll305785172bcra.png",
        "https://oss.duijh.cn/upload/test/ptl993028931cycm.png",
        "https://oss.duijh.cn/upload/test/swg567336686zxbz.png",
        "https://oss.duijh.cn/upload/test/gaj253624743erpz.png",
        "https://oss.duijh.cn/upload/test/iof663200030jztw.png",
        "https://oss.duijh.cn/upload/test/cbt010917585zyub.png",
      ], //显示图片
      yimglist: [
        "https://oss.duijh.cn/upload/test/lyp116400300awdi.png",
        "https://oss.duijh.cn/upload/test/moh252043553aazm.png",
        "https://oss.duijh.cn/upload/test/ocx831089163yihb.png",
        "https://oss.duijh.cn/upload/test/tmp374138348kmtm.png",
        "https://oss.duijh.cn/upload/test/lhz335072798obwz.png",
        "https://oss.duijh.cn/upload/test/tei267945198wdjh.png",
      ], //隐藏图片
      aimg: true, //控制图片的
      info: [], //行业咨询
      banner: [], //banner 图
      Source: [], //源数据
      cla: 0,
      ruleForm: {
        tabletype: "",
        tableke: "",
        ipone: "",
      },
      rules: {
        tabletype: [
          {
            required: true,
            message: "请选择类型",
            trigger: "change",
          },
        ],
        tableke: [
          {
            required: true,
            message: "请输入克数",
            trigger: "blur",
          },
        ],
        ipone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
        tableaddress: [
          {
            required: true,
            message: "请输入地址",
            trigger: "blur",
          },
        ],
      },
      aid: 1,
      ipone: "", //手机
      gram: "", //克数
      ty: "", //类型
      jin: "333.99",
      type: 0,
      dialogVisible: false,
      ss: {}, //省市级数据
      cs: true, //开启城市
      cheng: "", // 总数据
      shi: "",
      chen: ["测试"], //城市的数据
      isShow: "", //城市省的选中
      type_s: 0, //当前展示省还是市

      top: {
        1: "dujia",
        5: "hangye",
        6: "hezuo",
        4: "anquan",
        3: "maijin",
        2: "gongsi",
      },
    };
  },
  updated() {},
  mounted() {
    var that = this;
    setTimeout(function () {
      that.advallseleavefun();
      var swipe = that.$refs.swip;
      var mySwiper = new Swiper(swipe, {
        freeMode: true,
        loop: true,
        centeredSlides: true,
        slidesPerView: 4,
        spaceBetween: 25,
        autoplay: true, //自动切换
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    }, 200);

    this.$nextTick(function () {
      if (this.$route.params.id != null) {
        document
          .getElementById(this.top[this.$route.params.id])
          .scrollIntoView({
            behavior: "smooth",
          });
      } else {
        //停止页面刷新
        // window.location.href = "http://www.duijin.net/?#/"
      }
    });
    window.addEventListener("scroll", this.aboutUSfun);
    // window.addEventListener("scroll", this.Industryfun);
    // window.addEventListener("scroll", this.Industryrightfun);
  },
  created() {
    //获取金价
    var _this = this;
    this.$axios
      .get("https://duijh.cn:3001/jeecg-boot/duijh/goldPriceEpjRealtime/ssjj")
      .then(function (resp) {
        if (resp.data.code == "200") {
          var j = resp.data.message;

          var i = j.split(".")[1];
          if (i <= 9) {
            var a = i.indexOf(0);
            if (a == "0") {
              _this.jin = j;
            } else {
              _this.jin = j.split(".")[0] + "." + j.split(".")[1] + "0";
            }
          } else if (i == null) {
            _this.jin = j.split(".")[0] + "." + "00";
          } else {
            _this.jin = resp.data.message;
          }
        } else {
          // this.$message.error("系统异常情稍后在试");
        }
      });

    var ss1 = square();
    this.ss = ss1;
    console.log(this.$route.params.id);

    // 获取页面所需图片数据
    this.$axios
      .get(
        " https://duijh.cn:3001/jeecg-boot/duijh/officialWebsite/list?pageNo=1&pageSize=100"
      )
      .then(function (resp) {
        if (resp.data.code == "200") {
          var da = resp.data.result.records;

          // _this.banner =  imgpathone.split(',')
          _this.Source[0] = da[2].imgpathone;
          _this.Source[1] = da[4].imgpathone;
          _this.Source[2] = da[5].imgpathone;
          
         
         
         
          for (let index = 0; index < da.length; index++) {
            const element = da[index];

            if (element.headline == "卖家秀") {
              _this.xiu.push(da[index]);
            }
            if (element.designation == 'PC 行业资讯') {
              _this.info.push(da[index])
            }
          }
         _this.info.push(da[7]);
          console.log(_this.xiu,"111212121212121212");
        } else {
          this.$message.error();
        }
      });
    this.in();
    if ((window.outerWidth < 1232) & (window.outerHeight < 900)) {
      this.aimg = false;
      console.log("2");
    } else {
    }
  },

  methods: {
    movein(kid) {
      this.sellgoid = kid;
    },
    advfun(aid) {
      this.$refs["adv1"].style.width = "";
      this.$refs["adv2"].style.width = "";
      this.$refs["adv3"].style.width = "";
      this.$refs["adv4"].style.width = "";
      this.$refs["adv5"].style.width = "";
      this.$refs["adv6"].style.width = "";
      console.log("r");
      this.advnum = aid;
      this.aid = aid;
      this.$refs["adv" + aid].style.width = "42.71%";
      this.$refs["adv" + aid].style.transition = "width 500ms";

      this.$refs["adv" + this.aid].style.background =
        "url(" + this.imglist[aid - 1] + ")";
      this.$refs["adv" + this.aid].style.backgroundSize = "100% 100%";
      var array = this.$refs["adv" + this.aid].parentNode.childNodes;
      for (let index = 0; index < array.length; index++) {
        if (index != aid - 1) {
          array[index].children[0].style.display = "block";
        }
      }
      this.$refs["adv" + this.aid].children[0].style.display = "none";
    },
    advseleavefun(aid) {
      this.$refs["adv1"].style.width = "";
      this.$refs["adv2"].style.width = "";
      this.$refs["adv3"].style.width = "";
      this.$refs["adv4"].style.width = "";
      this.$refs["adv5"].style.width = "";
      this.$refs["adv6"].style.width = "";

      this.$refs["adv" + this.aid].style.background =
        "url(" + this.yimglist[aid - 1] + ")";
      this.$refs["adv" + this.aid].style.backgroundSize = "100% 100%";
    },
    advallseleavefun() {
      this.$refs["adv1"].style.background = "url(" + this.yimglist[0] + ")";
      this.$refs["adv1"].style.backgroundSize = "100% 100%";

      this.$refs["adv2"].style.background = "url(" + this.yimglist[1] + ")";
      this.$refs["adv2"].style.backgroundSize = "100% 100%";

      this.$refs["adv3"].style.background = "url(" + this.yimglist[2] + ")";
      this.$refs["adv3"].style.backgroundSize = "100% 100%";

      this.$refs["adv4"].style.background = "url(" + this.yimglist[3] + ")";
      this.$refs["adv4"].style.backgroundSize = "100% 100%";

      this.$refs["adv5"].style.background = "url(" + this.yimglist[4] + ")";
      this.$refs["adv5"].style.backgroundSize = "100% 100%";

      this.$refs["adv6"].style.background = "url(" + this.yimglist[5] + ")";
      this.$refs["adv6"].style.backgroundSize = "100% 100%";

      this.$refs["adv" + this.aid].style.width = "42.71%";
      this.$refs["adv" + this.aid].style.transition = "width 500ms";
      this.$refs["adv" + this.aid].style.background =
        "url(" + this.imglist[this.aid - 1] + ")";
      this.$refs["adv" + this.aid].style.backgroundSize = "100% 100%";
    },

    clickabout(eid) {
      this.company = true;

      this.aboutnum = eid;
      if (eid == 1) {
        this.aboutvalue =
          "兑鑫行是北京市国资委监管一级企业“北京工美集团”旗下品牌定点黄金回收、以旧换新平台。上线至今，积极响应政府的“循环经济”号召，专注于黄金产业链效率提升，切实解决消费者“买金容易，卖金难，换新难’的痛点。并荣获高新技术企业证书，并与京东...";
      } else if (eid == 2) {
        this.aboutvalue =
          "持照运营：兑鑫行是国家市场监督管理局批准持照营业的黄金回收平台；质检服务：兑鑫行指定国家首饰质量检验检测中心（National Jewellery Quality Supervision and Inspection Center，简称NJQSIC）为官方唯一质检合作机构，为兑鑫行的用户提供国家级检测服务...";
      } else {
        this.aboutvalue =
          "中国黄金协会A类会员：中国黄金协会是2001年经国家经济贸易委员会和中华人民共和国民政部正式批准和注册登记的全国性社团组织。中国珠宝玉石首饰行业协会A类会员：中国珠宝玉石首饰行业协会简称中宝协，成立于1991年，中宝协以“服务企业、发展产业、规范行业”为宗旨...";
      }
      var that = this;
      setTimeout(function () {
        that.company = false;
      }, 600);
    },
    Securitseouver(id) {
      console.log("入");
      // 加入透明度
      this.$refs["securitimg" + id].style.opacity = 1;
      this.$refs["masksecuritimg" + id].style.width = "47px";
      this.$refs["masksecuritimg" + id].style.transition = "width 500ms";
      //  this.$refs["securitimgenlarge" + id].style.cssText = "animation: bigger 2s;transform:scale(1.2);";
      // 加入背景色
    },
    Securitleave(id) {
      console.log("出");
      this.$refs["securitimg" + id].style.opacity = 0;
      this.$refs["masksecuritimg" + id].style.width = "0px";
    },
    Industryfun() {},
    aboutUSfun() {
      !isElementNotInViewport(this.$refs.aboutUS, this)
        ? (this.aboutUS = true)
        : "";
      !isElementNotInViewport(this.$refs.sellerimg)
        ? (this.sellerimg = true)
        : "";
        !isElementNotInViewport(this.$refs.advant)
        ? (this.advant = true)
        : "";  
      
      !isElementNotInViewport(this.$refs.Security)
        ? (this.Security = true)
        : ""; 

!isElementNotInViewport(this.$refs.parten)
        ? (this.parten = true)
        : ""; 
    !isElementNotInViewport(this.$refs.infoma)
        ? (this.infoma = true)
        : ""; 
      !isElementNotInViewport(this.$refs.seller)
        ? (this.seller = true)
        : "";
      !isElementNotInViewport(this.$refs.Industry)
        ? (this.Industry = true)
        : "";
      !isElementNotInViewport(this.$refs.Industryright)
        ? (this.Industryright = true)
        : "";
      if (this.viewnumber == 0) {
        this.viewnumber = 1;
        !isElementNotInViewport(this.$refs.company)
          ? (this.company = true)
          : "";
      }
      var that = this;
      if (that.Industryright) {
        setTimeout(function () {
          that.industinfovalue = true;
          console.log(that.industinfovalue);
        }, 1000);
      }

      setTimeout(function () {
        that.company = false;
      }, 600);
    },
    Industryrightfun() {},
    quer() {
      this.$refs.zhe.style.display = "none";
    },
    in() {
      var _this = this;

      window.setInterval(() => {
        this.$axios
          .get(
            "https://duijh.cn:3001/jeecg-boot/duijh/goldPriceEpjRealtime/ssjj"
          )
          .then(function (resp) {
            if (resp.data.code == "200") {
              var j = resp.data.message;

              var i = j.split(".")[1];

              if (i <= 9) {
                var a = i.indexOf(0);
                if (a == "0") {
                  _this.jin = j;
                } else {
                  _this.jin = j.split(".")[0] + "." + j.split(".")[1] + "0";
                }
              } else if (i == null) {
                _this.jin = j.split(".")[0] + "." + "00";
              } else {
                _this.jin = resp.data.message;
              }
            } else {
              // _this.$message.error("系统异常情稍后在试");
            }
          });
      }, 3000);
    },
    ca(id) {
      this.$refs[id].style = "color:#237bfe";
    },
    cca(id) {
      this.$refs[id].style = "color:#b1b8c3";
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;

          if (reg.test(this.ruleForm.ipone)) {
          } else {
            this.$message.error("手机号格式不正确请重新输入");
            return;
          }
          if (this.chen == "" || this.shi == "") {
            this.$message.error("请输入完成的地址信息");
            return;
          }
          console.log(new Date());
          var params = {
            createTime: new Date().toString, //创建时间
            mobilePhone: this.ruleForm.ipone, // 电话
            type: this.ruleForm.tabletype, //类型
            userAddress: this.cheng + this.shi, //地址
            weightNum: this.ruleForm.tableke, // 克数
          };

          this.$axios
            .post(
              " https://duijh.cn:3001/jeecg-boot/duijh/bespeakEnregisterLog/add",
              params
            )
            .then((res) => {
              this.arr = this.$refs.zhezhao.style.display = "none"; //获取DOM元素节点的值
              if (res.data.code == "200") {
                this.$refs.zhe.style.display = "inline";
              } else {
                this.$message.error(res.data.message);
              }
            });
        } else {
          console.log("error submit!!");
          return;
        }
      });
    },
    hangyexq(i) {
      this.$router.push({
        path: "/about",
        params: { setid: i },
      });
    },
    gongsi() {
      this.$router.push("/details");
    },

    biao(itemssss) {
      var item = itemssss;
      console.log(item);
      var _this = this;
      //控制选中样式的
      this.isShow = item;
      console.log("+++", this.type_s);
      if (this.type_s == "0") {
        //省的展示
        this.cheng = item + "/";
      } else {
        //市的展示
        this.shi = item;
        this.cs = true;

        console.log(this.cs);

        this.$refs.tankuang.style.height = "500px";
        this.$forceUpdate();
        return;
      }

      //取市区

      if (this.ss[item]["市辖区"] == undefined) {
        console.log(this.ss[item]);
        this.chen = [];

        Reflect.ownKeys(this.ss[item]).forEach((key) => {
          this.chen.push(key);
        });
        this.chen.pop();
        let arr = this.chen;
        let size = 3;
        //取4个数组
        let [start, end, result] = [null, null, []];
        for (let i = 0; i < Math.ceil(arr.length / size); i++) {
          start = i * size;
          end = start + size;
          result.push(arr.slice(start, end));
        }
        //重制数据
        this.chen = result;
        //  修改标识(当前展示为市)

        this.type_s = 1;
      } else {
        //特别行政区
        var ar = this.ss[item];
        var arr = ar["市辖区"];
        var size = 3;
        //取4个数组
        var [start, end, result] = [null, null, []];
        _this.chen = [];
        for (var i = 0; i < Math.ceil(arr.length / size); i++) {
          start = i * size;
          end = start + size;
          result.push(arr.slice(start, end));
        }

        //重制数据

        _this.chen = result;
        //  修改标识(当前展示为市)
        _this.type_s = 1;
      }
    },
    xiala() {
      //  修改标识(当前展示为省)
      this.type_s = 0;
      //  省市为空
      // this.cheng = '',
      // this.shi = ''

      this.chen = [];
      // 开启选择框，并添加高度
      var s = !this.cs;
      this.cs = s;
      if (!this.cs) {
        this.$refs.tankuang.style.height = "75%";
      } else {
        this.$refs.tankuang.style.height = "500px";
      }

      //  给省渲染数据
      var s = this.ss;
      Reflect.ownKeys(s).forEach((key) => {
        this.chen.push(key);
      });

      this.chen.pop();
      let arr = this.chen;
      let size = 3;
      //取4个数组
      let [start, end, result] = [null, null, []];
      for (let i = 0; i < Math.ceil(arr.length / size); i++) {
        start = i * size;
        end = start + size;
        result.push(arr.slice(start, end));
      }

      this.chen = result;
      this.$forceUpdate();
    },
    handleCommand(command) {
      this.ruleForm.tabletype = command;
    },
    dianwo() {
      this.arr = this.$refs.zhezhao.style.display = ""; //获取DOM元素节点的值
    },
    hidder() {
      this.arr = this.$refs.zhezhao.style.display = "none"; //获取DOM元素节点的值
    },
  },
};
</script>
// 1920 px 分辨率适配

<style>
/* @media screen and (max-width: 1920px) { */
#hearder-width {
  width: 100%;
  height: 91px;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 999;
}
.advclass {
  width: 615px !important;
  transition: width 500ms !important;
}
.advantage {
  margin-top: 161px;

  overflow: hidden;
  width: 87.14%;
  margin-right: auto;
  margin-left: auto;
}
.advantagetite {
  width: 158.2px;
  height: 39px;
}
.advantageEnglish {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #282828;
  margin-top: 10px;
}
.advantageImgList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 45px;
}
.amplification {
  width: 615px;
  height: 391px;
  position: absolute;
  top: -61px;
}
.exclusively {
  width: 9.7%;
  height: 349px;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
}
.exclusively > p {
  margin: auto;
  font-size: 30px;
}
.exclusively > p > span {
  display: block;
  margin-bottom: 10px;
}
.amplificationbg {
  width: 100%;
  height: 330px;
  background: #c77a0e;
  position: relative;
}

/* 终 独家优势 */

/* 开 安全保障 */
.SecurityBundle {
  margin-top: 161px;

  overflow: hidden;
  width: 87.14%;
  margin-right: auto;
  margin-left: auto;
}
.securitybundletite {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #282828;
  margin-top: 10px;
}
.securitybundleimg {
  width: 158px;
  height: 39px;
}
.securityBundlevalue {
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.securitall {
  overflow: hidden;
}
.securitall:hover .securityBundlevalueimg {
  transform: scale(1.2);
}
.securityBundlevalueimg {
  /* width: 19.79%; */
  /* height: 441px; */
  transition: all 0.5s;
}
.securMask {
  position: absolute;
  width: 100%;
  height: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.3);
  transition: all 0.5s;
  opacity: 0;
}
.masktite {
  font-size: 23px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-top: 60px;
  margin-left: 46px;
}
.maskvalue {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-top: 14px;
  margin-left: 46px;
}
.maskdecorate {
  /* width: 41px; */
  width: 0px;
  height: 6px;
  background: #c77a0e;
  margin-top: 31px;
  margin-left: 46px;
}

/* 终 安全保障 */
/* 开 卖家秀 */
.selleruserinfo {
  width: 87.14%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 161px;
}
.SellerController {
  display: block;
  margin: 161px auto 0;
}
.SellerControllerimg {
  width: 117px;
  height: 39px;
}
.SellerControllervalue {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #282828;
}

.sellercontrollerinfo {
  width: 458px;
  height: 408px;
  background: #ffffff;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 3px 20px 0px #eeeeee;
}
.slideshowwrapper {
  width: 100%;
  /* height: 285px; */
}
.slideshowvalue {
  padding-left: 17px;
  padding-right: 17px;
  margin-top: 30px;
}
.userloadPortrait {
  width: 48px;
  height: 48px;
  float: left;
  border-radius: 50%;
  margin-right: 8px;
}
.userblock {
  float: left;
  line-height: 48px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.usertime {
  float: right;
  line-height: 48px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.userinfo {
  margin-top: 16px;
  text-align: left;
  margin-bottom: 24px;
}
.userinfotite {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ba721a;
  line-height: 30px;

  text-align: left;
}
.userinfovlue {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 30px;

  text-align: left;
}
.swiperimgs {
  text-align: center;
}
.swiperimg {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

/* 终 卖家秀 */
/* 开 行业资讯 */
.information {
  /* margin-top: 96px; */
  width: 87.14%;
  margin: 161px auto 82px;  
  overflow: hidden;
}
.infomationimg {
  width: 159px;
  height: 40px;
}
.infomationvalue {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #282828;
  margin-top: 10px;
}
.infomationinfolist {
  margin-top: 45px;

  display: flex;
  justify-content: space-between;
  margin-bottom: 59px;
}
.infomationblock{
  width: 47.3%;
}
.infomationblock img {
  width: 100%;
  /* height: 296px; */
}
.infoamtionblocktite {
  /* width: 582px; */
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin-top: 43px;
  white-space: nowrap;

  overflow: hidden;

  text-overflow: ellipsis;
}
.infoamtiontime {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #cecece;
  margin-top: 10px;
}
.infoamtionbody {
  margin-top: 10px;
  /* width: 582px; */
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 41px;
  overflow: hidden;

  text-overflow: ellipsis;

  display: -webkit-box;

  -webkit-box-orient: vertical;

  -webkit-line-clamp: 3;
}
.infoamtionbottom {
  width: 179px;
  height: 56px;
  background: #c77a0e;
  border-radius: 28px;
  margin-top: 40px;
  font-size: 23px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 56px;
}

/* 终 行业资讯 */

/* 开 合作机构 */
.partners {
  width: 87.14%;

  margin: 161px auto;
}
.partnersimg {
  width: 119px;
  height: 29px;
}
.partnersvlaue {
  font-size: 15px;
  font-family: PingFang SC;

  color: #282828;
  margin-top: 8px;
}
.partnersvlaueimg {
  margin: 0 auto;
  display: block;
  margin-bottom: 119px;
  width: 100%;
  margin-top: 45px;
}
/* 终 合作机构 */
/* 始卖金  1920 屏幕*/
.sellyourimg {
  position: absolute;
  width: 87.14%;
  right: 0px;
  left: 0px;
  margin: 0 auto;
  margin-top: 71px;
}
.sellyourimg img {
  width: 183px;
  height: 65px;
}
.profitimg {
  width: 13px;
  height: 23px;
}
.sellyourgoldsyracuse {
  margin-top: 94px;
  position: relative;
}
.backgroundvar {
  width: 100%;
  position: absolute;
  z-index: -1;
}
.ofbackgroundvar {
  width: 85.42%;
  margin: 145px auto 0;
}
.ofbackgroundvarimg {
  width: 100%;
  margin-top: 190px;
}
.profitconfidential {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 36%;
  width: 73.42%;
  align-items: center;
  left: 0px;
  right: 0px;
  margin: 0 auto;
}
.profitconfidentialdiv {
  width: 340px;
  height: 419px;
  background: #ffffff;
  border-radius: 15px;
}
.iconnumber {
  width: 111px;
  height: 42px;
  margin-top: 0px;
  float: right;
}
.Subpicture {
  width: 110px;
  height: 110px;
  margin: 75px auto 0;
  display: block;
  position: relative;
  z-index: 1;
  left: 0;
  right: 0;
}
.shadow {
  width: 84px;
  height: 31px;

  border-radius: 50%;
  margin: 0 auto;
  position: absolute;

  left: 0;
  right: 0;
}
.sellingtitle {
  font-size: 23px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #353a3f;
  line-height: 23px;
  margin-top: 56px;
  text-align: center;
}
.sellingvalue {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  text-align: center;
  margin-top: 13px;
}
.sellingvaluetwo {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  text-align: center;
}
/* 终卖金 */
.business {
  height: 20px;
  font-size: 20px;
  font-family: PingFang SC;
  color: #ffffff;
  text-align: center;
  line-height: 60px;
  margin: 0 auto;
}
.business_state {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 60px;
}
/* .field_default_value {
  width: 30.4%;
  height: 60px;
  background: #c77a0e;
  border-radius: 6px;
}
.field_default_value_state {
  background: #ffffff;
  border: NaNpx solid #d2d2d2;
  border-radius: 5px;
} */

.field_default_value {
  width: 30.4%;
  height: 45px;
  background: #c77a0e;
  border-radius: 5px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 45px;
  text-align: center;
}
.field_default_value_state {
  width: 30.4%;
  height: 45px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 45px;
  text-align: center;
}
.groups {
  display: flex;
  justify-content: space-between;
  margin-top: 105px;
}
.desktop {
  font-size: 24px;
  color: #c77a0e;
}
.leftassociative {
  /* border-style: solid; */
  width: 44.58%;
  margin-left: 140px;
}
.rightassociative {
  width: 31.07%;
  /* border-style: solid; */

  position: absolute;
  bottom: 0px;
  right: 0;
  margin-right: 166px;
}
.eating {
  height: 16px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #282828;
  margin-top: 13px;
}

.company {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 75px;
  margin-top: 50px;
}
.Introduction {
  height: 39px;
  font-size: 40px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #282828;
}

.exclusive {
  display: flex;
  justify-content: space-between;
  position: relative;
}
a {
  text-decoration: none;
}

.router-link-active {
  text-decoration: none;
}

.hangye_xq {
  width: 56px;
  height: 14px;
  font-size: 14px;

  color: #237bfe;
  /*line-height: 24px;*/
  /*margin-top: 4px;*/
}

.hangye_xx {
  width: 56px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #b1b8c3;
  line-height: 24px;
  margin-top: 4px;
  margin-left: 9px;
}

.hangye_fg {
  width: 100%;
  height: 2px;
  background: #f8f8f8;
  margin-top: 51px;
  display: block;
  margin: 0 auto;
}

.hangye_yq {
  height: 95px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 24px;
}

.hangye_header {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  text-align: left;
  height: 24px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #2f2d3a;
  margin-left: 2px;
}

.hangye_tap {
  width: 25%;
  height: 30%;
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(202, 207, 228, 0.45);
  text-align: center;
  display: inline-block;
  margin-bottom: 109px;
}

.hangye_ch {
  width: 146px;
  height: 38px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #2f2d3a;

  margin-top: 21px;
  margin-bottom: 30px;
}

.gongsi_ch {
  font-size: 23px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #2f2d3a;

  margin-top: 21px;
}

.hangye_en {
  display: inline;
  text-align: center;
  height: 18px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  line-height: 40px;
  display: block;
  margin: 0 auto;
}

.gongsi {
  font-size: 17px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  line-height: 40px;
}

.active {
  width: 70px;
  height: 36px;
  background: #2f2d3a;
  border-radius: 6px;
  border-radius: 6px;
  margin-left: 20px;
  border: none;
  outline: none;
  color: #fefefe;
}

.cs {
  width: 51px;
  height: 14px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-left: 8px;
}

.xe {
  width: 81px;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #2f2d3a;
  margin-left: 50px;
  top: 5px;
}

.x {
  width: 63px;
  height: 16px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #333333;
  margin-left: 20px;
  margin-bottom: 8px;
  margin-top: 5px;
}

.chengshi {
  width: 75%;
  height: 30%;
  background: #f2f2f2;
  border-radius: 8px;
}

.ko {
  width: 25%;
  height: 36px;
  background: #ffffff;
  border-radius: 6px;
  margin-left: 20px;
  border: none;
  outline: none;
}

input::-webkit-input-placeholder {
  font-size: 14px;

  color: #999999;
}

.type {
  width: 66%;
  height: 45px;
  background: #f2f2f2;
  border-radius: 8px;
  margin-left: 41px;
  margin-bottom: 30px;
  border: none;
  outline: none;
  padding-left: 20px;
}

.ziti {
  width: 103px;
  height: 24px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #2f2d3a;
  margin-left: 33px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.zit {
  width: 103px;
  height: 24px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #2f2d3a;
  margin-top: 30px;
  margin-bottom: 30px;
}

.tup {
  /*width: 112px;*/
  /*height: 37px;*/

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.zhezhao {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.tankuang {
  position: relative;
  background: #f8f8f8;
  width: 35%;
  height: 500px;
  border-radius: 5px;
  margin: 2% auto;
}

#header {
  height: 100%;
}

#header-right {
  position: absolute;
  width: 24px;
  height: 24px;
  background: #ffffff;
  text-align: center;
}

.duiding {
  width: 100%;
  height: 80px;
  background: #c77a0e;
  position: relative;
}

.header {
  width: 900px;

  height: 160px;
  background: #ffffff;
  box-shadow: 0px 21px 50px 0px rgba(202, 207, 228, 0.45);
  border-radius: 20px;

  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 200;
  top: 87%;
}

.ding {
  height: 26px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #2f2d3a;
  margin-left: 30px;
  margin-top: 3%;
}

.jiage {
  height: 52px;
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #c71b1a;
}

.biaojia {
  width: 75px;
  height: 23px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #c71b1a;
}

.zhongfu {
  margin-top: -3%;
  text-align: center;
}

.buttonz {
  /*width: 520px;*/
  /*height: 80px;*/

  width: 300px;
  height: 60px;
  background: #2f2d3a;
  border: 1px solid #2f2d3a;
  border-radius: 35px;
}

.buzt {
  width: 170px;
  height: 38px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.wen {
  width: 398px;
  height: 57px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #e60000;
  line-height: 22px;
}
.swiper-container {
  /* width: 400px; */
  /* height: 458px; */
  margin-top: 45px;
  overflow: visible;
}

.swiper-slide {
  /* width: 300px; */
  background: #ffffff;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 3px 20px 0px #eeeeee;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;

  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.9);
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}

</style>

<style scoped>
.el-menu-item {
  border-right: 1px solid #000 !important;
}
.el-input__inner {
  background-color: #f2f2f2 !important;
}

.el-button--info {
  background: #2f2d3a;
  border: 1px solid #2f2d3a;
}

.el-button.is-round {
  border-radius: 40px;
}

.el-dialog__body {
  padding: 0px 20px;
}
</style>









































/* 1440 所使用的css样式格式 */
<style scoped>
@media screen and (min-width: 1366px) and (max-width: 1440px) {

  .leftassociative {
    /* border-style: solid; */
    width: 44.58%;
    margin-left: 105px;
  }
  .rightassociative {
    width: 36.07%;
    /* border-style: solid; */

    position: absolute;
    bottom: 1px;
    right: 0;
    margin-right: 105px;
  }
  .Introduction {
    height: 29px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
  }
  .eating {
    height: 12px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
    margin-top: 10px;
  }
  .company {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 56px;
    margin-top: 38px;
  }
  .desktop {
    font-size: 18px;
  }
  .groups {
    margin-top: 79px;
    display: flex;
    justify-content: space-between;
  }
  .field_default_value {
    width: 30.4%;
    height: 45px;
    background: #c77a0e;
    border-radius: 5px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 45px;
    text-align: center;
  }
  .field_default_value_state {
    width: 30.4%;
    height: 45px;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 45px;
    text-align: center;
  }

  .business {
    width: 150px;
    height: 15px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 45px;
    margin: 0 auto;
  }

  /* 始卖金 */
  .sellyourimg {
    position: absolute;
    width: 87.14%;
    right: 0px;
    left: 0px;
    margin: 0 auto;
    margin-top: 55px;
  }
  .sellyourimg img {
    width: 137px;
    height: 51px;
  }
  .profitimg {
    width: 13px;
    height: 23px;
  }
  .sellyourgoldsyracuse {
    margin-top: 94px;
    position: relative;
  }
  .backgroundvar {
    width: 100%;
    position: absolute;
    z-index: -1;
  }
  .ofbackgroundvar {
    width: 85.42%;
    margin: 145px auto 0;
  }
  .ofbackgroundvarimg {
    width: 100%;
    margin-top: 142px;
  }
  .profitconfidential {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 203px;

    width: 73.42%;
    align-items: center;
    left: 0px;
    right: 0px;
    margin: 0 auto;
  }
  .profitconfidentialdiv {
    width: 294px;
    height: 362px;
    background: #ffffff;
    border-radius: 15px;
  }

  .iconnumber {
    width: 83px;
    height: 31px;
    margin-top: 0px;
    float: right;
  }
  .Subpicture {
    width: 83px;
    height: 82px;
    margin: 75px auto 0;
    display: block;
    position: relative;
    z-index: 1;
    left: 0;
    right: 0;
  }
  .shadow {
    width: 84px;
    height: 31px;

    border-radius: 50%;
    margin: 0 auto;
    position: absolute;

    left: 0;
    right: 0;
  }
  .sellingtitle {
    font-size: 23px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #353a3f;
    line-height: 23px;
    margin-top: 40px;
    text-align: center;
  }
  .sellingvalue {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    text-align: center;
    margin-top: 13px;
  }
  .sellingvaluetwo {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    text-align: center;
  }
  /* 终卖金 */

  /* 开 独家优势 */
  .advclass {
    width: 615px !important;
    transition: width 500ms !important;
  }
  .advantage {
    margin-top: 96px;

    overflow: hidden;
    width: 87.14%;
    margin-right: auto;
    margin-left: auto;
  }
  .advantagetite {
    width: 114px;
    height: 29px;
  }
  .advantageEnglish {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
    margin-top: 10px;
  }
  .advantageImgList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
  }
  .amplification {
    width: 615px;
    height: 391px;
    position: absolute;
    top: -61px;
  }
  .exclusively {
    width: 8.5%;
    height: 330px;
    position: relative;
  }
  .exclusively > p {
    margin: auto;
    font-size: 23px;
  }
  .exclusively > p > span {
    display: block;
    margin-bottom: 10px;
  }

  .amplificationbg {
    width: 100%;
    height: 330px;
    background: #c77a0e;
    position: relative;
  }

  /* 终 独家优势 */

  /* 开 安全保障 */
  .SecurityBundle {
    margin-top: 96px;

    overflow: hidden;
    width: 87.14%;
    margin-right: auto;
    margin-left: auto;
  }
  .securitybundletite {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
    margin-top: 10px;
  }
  .securitybundleimg {
    width: 118px;
    height: 29px;
  }
  .securityBundlevalue {
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .securitall {
    overflow: hidden;
  }
  .securitall:hover .securityBundlevalueimg {
    transform: scale(1.2);
  }
  .securityBundlevalueimg {
    width: 284px;
    height: 441px;
    transition: all 0.5s;
  }
  .securMask {
    position: absolute;
    width: 100%;
    height: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.3);
    transition: all 0.5s;
    opacity: 0;
  }
  .masktite {
    font-size: 23px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    margin-top: 60px;
    margin-left: 46px;
  }
  .maskvalue {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin-top: 14px;
    margin-left: 46px;
  }
  .maskdecorate {
    width: 0px;
    height: 6px;
    background: #c77a0e;
    margin-top: 31px;
    margin-left: 46px;
  }

  /* 终 安全保障 */
  /* 开 卖家秀 */
  .selleruserinfo {
    width: 87.14%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 90px;
  }
  .SellerController {
    display: block;
    margin: 96px auto 0;
  }
  .SellerControllerimg {
    width: 88px;
    height: 29px;
  }
  .SellerControllervalue {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
  }

  .sellercontrollerinfo {
    width: 458px;
    /* height: 408px; */
    background: #ffffff;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 3px 20px 0px #eeeeee;
  }
  .slideshowwrapper {
    width: 100%;
    /* height: 285px; */
  }
  .slideshowvalue {
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 30px;
  }
  .userloadPortrait {
    width: 48px;
    height: 48px;
    float: left;
    border-radius: 50%;
    margin-right: 8px;
  }
  .userblock {
    float: left;
    line-height: 48px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .usertime {
    float: right;
    line-height: 48px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .userinfo {
    margin-top: 16px;
    text-align: left;
    margin-bottom: 24px;
  }
  .userinfotite {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ba721a;
    line-height: 30px;

    text-align: left;
  }
  .userinfovlue {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;

    text-align: left;
  }
  .swiperimgs {
    text-align: center;
  }
  .swiperimg {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }

  /* 终 卖家秀 */
  /* 开 行业资讯 */
  .information {
    /* margin-top: 96px; */
    width: 87.14%;
    margin: 96px auto 61px;
    overflow: hidden;
  }
  .infomationimg {
    width: 119px;
    height: 30px;
  }
  .infomationvalue {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
    margin-top: 10pxf;
  }
  .infomationinfolist {
    margin-top: 35px;

    display: flex;
    justify-content: space-between;
    margin-bottom: 59px;
  }
.infomationblock{
  width: 47.3%;
}
.infomationblock img {
  width: 100%;
  /* height: 296px; */
}
  /* .infomationblock img {
    width: 582px;
    height: 296px;
  } */
  .infoamtionblocktite {
    /* width: 582px; */
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-top: 32px;
    white-space: nowrap;

    overflow: hidden;

    text-overflow: ellipsis;
  }
  .infoamtiontime {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #cecece;
    margin-top: 10px;
  }
  .infoamtionbody {
    margin-top: 10px;
    /* width: 582px; */
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 31px;
    overflow: hidden;

    text-overflow: ellipsis;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 3;
  }
  .infoamtionbottom {
    width: 179px;
    height: 56px;
    background: #c77a0e;
    border-radius: 28px;
    margin-top: 40px;
    font-size: 23px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 56px;
  }

  /* 终 行业资讯 */

  /* 开 合作机构 */
  .partners {
    width: 87.14%;

    margin: 96px auto;
  }
  .partnersimg {
    width: 119px;
    height: 29px;
  }
  .partnersvlaue {
    font-size: 15px;
    font-family: PingFang SC;

    color: #282828;
    margin-top: 8px;
  }
  .partnersvlaueimg {
    margin: 0 auto;
    display: block;
    margin-bottom: 119px;
    width: 100%;
    margin-top: 35px;
  }
  /* 终 合作机构 */
  .swiper-container {
  /* width: 400px; */
  /* height: 458px; */
  margin-top: 35px;
  overflow: visible;
}

.swiper-slide {
  /* width: 300px; */
  background: #ffffff;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 3px 20px 0px #eeeeee;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;

  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.9);
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}
}
</style>

<style >

</style>
<style type="text/css">
@-webkit-keyframes bounce-down {
  25% {
    -webkit-transform: translateY(-10px);
  }
  50%,
  100% {
    -webkit-transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(10px);
  }
}

@keyframes bounce-down {
  25% {
    transform: translateY(-10px);
  }
  50%,
  100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
}

.animate-bounce-down {
  background: #333;
  width: 100px;
  height: 100px;
  margin: 100px auto;
  -webkit-animation: bounce-down 1.5s linear infinite;
  animation: bounce-down 1.5s linear infinite;
}

@-webkit-keyframes bounce-up {
  25% {
    -webkit-transform: translateY(10px);
  }
  50%,
  100% {
    -webkit-transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(-10px);
  }
}

@keyframes bounce-up {
  25% {
    transform: translateY(10px);
  }
  50%,
  100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-10px);
  }
}

.animate-bounce-up {
  -webkit-animation: bounce-up 1.4s linear infinite;
  animation: bounce-up 1.4s linear infinite;
}
</style>

<style scoped>
@media screen and (min-width: 960px) and (max-width: 1366px) {

  .leftassociative {
    /* border-style: solid; */
    width: 44.58%;
    margin-left: 105px;
  }
  .rightassociative {
    width: 36.07%;
    /* border-style: solid; */

    position: absolute;
    bottom: 1px;
    right: 0;
    margin-right: 105px;
  }
  .Introduction {
    height: 29px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
  }
  .eating {
    height: 12px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
    margin-top: 10px;
  }
  .company {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 56px;
    margin-top: 38px;
  }
  .desktop {
    font-size: 18px;
  }
  .groups {
    margin-top: 79px;
    display: flex;
    justify-content: space-between;
  }
  .field_default_value {
    width: 30.4%;
    height: 45px;
    background: #c77a0e;
    border-radius: 5px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 45px;
    text-align: center;
  }
  .field_default_value_state {
    width: 30.4%;
    height: 45px;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 45px;
    text-align: center;
  }

  .business {
    width: 150px;
    height: 15px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 45px;
    margin: 0 auto;
  }

  /* 始卖金 */
  .sellyourimg {
    position: absolute;
    width: 87.14%;
    right: 0px;
    left: 0px;
    margin: 0 auto;
    margin-top: 55px;
  }
  .sellyourimg img {
    width: 137px;
    height: 51px;
  }
  .profitimg {
    width: 13px;
    height: 23px;
  }
  .sellyourgoldsyracuse {
    margin-top: 94px;
    position: relative;
  }
  .backgroundvar {
    width: 100%;
    position: absolute;
    z-index: -1;
  }
  .ofbackgroundvar {
    width: 85.42%;
    margin: 145px auto 0;
  }
  .ofbackgroundvarimg {
    width: 100%;
    margin-top: 142px;
  }
  .profitconfidential {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 203px;

    width: 73.42%;
    align-items: center;
    left: 0px;
    right: 0px;
    margin: 0 auto;
  }
  .profitconfidentialdiv {
    width: 258px;
    height: 318px;
    background: #ffffff;
    border-radius: 15px;
  }

  .iconnumber {
    width: 83px;
    height: 31px;
    margin-top: 0px;
    float: right;
  }
  .Subpicture {
    width: 83px;
    height: 82px;
    margin: 75px auto 0;
    display: block;
    position: relative;
    z-index: 1;
    left: 0;
    right: 0;
  }
  .shadow {
    width: 84px;
    height: 31px;

    border-radius: 50%;
    margin: 0 auto;
    position: absolute;

    left: 0;
    right: 0;
  }
  .sellingtitle {
    font-size: 23px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #353a3f;
    line-height: 23px;
    margin-top: 40px;
    text-align: center;
  }
  .sellingvalue {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    text-align: center;
    margin-top: 13px;
  }
  .sellingvaluetwo {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    text-align: center;
  }
  /* 终卖金 */

  /* 开 独家优势 */
  .advclass {
    width: 615px !important;
    transition: width 500ms !important;
  }
  .advantage {
    margin-top: 96px;

    overflow: hidden;
    width: 87.14%;
    margin-right: auto;
    margin-left: auto;
  }
  .advantagetite {
    width: 114px;
    height: 29px;
  }
  .advantageEnglish {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
    margin-top: 10px;
  }
  .advantageImgList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 35px;
  }
  .amplification {
    width: 615px;
    height: 391px;
    position: absolute;
    top: -61px;
  }
  .exclusively {
    width: 8.5%;
    height: 330px;
    position: relative;
  }
  .exclusively > p {
    margin: auto;
    font-size: 23px;
  }
  .exclusively > p > span {
    display: block;
    margin-bottom: 10px;
  }

  .amplificationbg {
    width: 100%;
    height: 330px;
    background: #c77a0e;
    position: relative;
  }

  /* 终 独家优势 */

  /* 开 安全保障 */
  .SecurityBundle {
    margin-top: 96px;

    overflow: hidden;
    width: 87.14%;
    margin-right: auto;
    margin-left: auto;
  }
  .securitybundletite {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
    margin-top: 10px;
  }
  .securitybundleimg {
    width: 118px;
    height: 29px;
  }
  .securityBundlevalue {
    margin-top: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .securitall {
    overflow: hidden;
  }
  .securitall:hover .securityBundlevalueimg {
    transform: scale(1.2);
  }
  .securityBundlevalueimg {
    width: 250px;
    /* height: 441px; */
    transition: all 0.5s;
  }
  .securMask {
    position: absolute;
    width: 100%;
    height: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.3);
    transition: all 0.5s;
    opacity: 0;
  }
  .masktite {
    font-size: 23px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    margin-top: 60px;
    margin-left: 46px;
  }
  .maskvalue {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    margin-top: 14px;
    margin-left: 46px;
  }
  .maskdecorate {
    width: 0px;
    height: 6px;
    background: #c77a0e;
    margin-top: 31px;
    margin-left: 46px;
  }

  /* 终 安全保障 */
  /* 开 卖家秀 */
  .selleruserinfo {
    width: 87.14%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 90px;
  }
  .SellerController {
    display: block;
    margin: 96px auto 0;
  }
  .SellerControllerimg {
    width: 88px;
    height: 29px;
  }
  .SellerControllervalue {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
  }

  .sellercontrollerinfo {
    width: 458px;
    /* height: 408px; */
    background: #ffffff;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 3px 20px 0px #eeeeee;
  }
  .slideshowwrapper {
    width: 100%;
    /* height: 285px; */
  }
  .slideshowvalue {
    padding-left: 17px;
    padding-right: 17px;
    margin-top: 30px;
  }
  .userloadPortrait {
    width: 48px;
    height: 48px;
    float: left;
    border-radius: 50%;
    margin-right: 8px;
  }
  .userblock {
    float: left;
    line-height: 48px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .usertime {
    float: right;
    line-height: 48px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .userinfo {
    margin-top: 16px;
    text-align: left;
    margin-bottom: 24px;
  }
  .userinfotite {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ba721a;
    line-height: 30px;

    text-align: left;
  }
  .userinfovlue {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;

    text-align: left;
  }
  .swiperimgs {
    text-align: center;
  }
  .swiperimg {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }

  /* 终 卖家秀 */
  /* 开 行业资讯 */
  .information {
    /* margin-top: 96px; */
    width: 87.14%;
    margin: 96px auto 61px;
    overflow: hidden;
  }
  .infomationimg {
    width: 119px;
    height: 30px;
  }
  .infomationvalue {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
    margin-top: 10pxf;
  }
  .infomationinfolist {
    margin-top: 35px;

    display: flex;
    justify-content: space-between;
    margin-bottom: 59px;
  }
.infomationblock{
  width: 47.3%;
}
.infomationblock img {
  width: 100%;
  /* height: 296px; */
}
  /* .infomationblock img {
    width: 582px;
    height: 296px;
  } */
  .infoamtionblocktite {
    /* width: 582px; */
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-top: 32px;
    white-space: nowrap;

    overflow: hidden;

    text-overflow: ellipsis;
  }
  .infoamtiontime {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #cecece;
    margin-top: 10px;
  }
  .infoamtionbody {
    margin-top: 10px;
    /* width: 582px; */
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 31px;
    overflow: hidden;

    text-overflow: ellipsis;

    display: -webkit-box;

    -webkit-box-orient: vertical;

    -webkit-line-clamp: 3;
  }
  .infoamtionbottom {
    width: 179px;
    height: 56px;
    background: #c77a0e;
    border-radius: 28px;
    margin-top: 40px;
    font-size: 23px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    line-height: 56px;
  }

  /* 终 行业资讯 */

  /* 开 合作机构 */
  .partners {
    width: 87.14%;

    margin: 96px auto;
  }
  .partnersimg {
    width: 119px;
    height: 29px;
  }
  .partnersvlaue {
    font-size: 15px;
    font-family: PingFang SC;

    color: #282828;
    margin-top: 8px;
  }
  .partnersvlaueimg {
    margin: 0 auto;
    display: block;
    margin-bottom: 119px;
    width: 100%;
    margin-top: 35px;
  }
  /* 终 合作机构 */
  .swiper-container {
  /* width: 400px; */
  /* height: 458px; */
  margin-top: 35px;
  overflow: visible;
}

.swiper-slide {
  /* width: 300px; */
  background: #ffffff;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0px 3px 20px 0px #eeeeee;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;

  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.9);
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}
}

</style>





