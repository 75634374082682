<template>
  <div class="bottoms">
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="orderf">
          <p class="order">北京五凤城科技有限公司</p>
          <p class="dizhi" style="margin-top: 14px">北京市丰台区马家堡东路88号五号楼</p>
          <p class="phone" style="margin-top: 16px">400-009-3003</p>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="tu">
          <div style="float: left">
            <img
              style="width: 82px"
              src="../../public/WechatIMG15cc75e7b2f15a688d7ff4b905dfb2ce.png"
            />
            <p id="tup">兑鑫行小程序</p>
          </div>
          <div style="float: left" class="you">
            <img
              style="width: 82px"
              src="../../public/WechatIMG5c7d0622180e6203b2d34c5e8408e23d.png"
            />
            <p id="tups">扫码联系客服</p>
          </div>
        </div>
      </el-col>
    </el-row>
    <div style="height: 40px"></div>
    <div class="fenge"></div>

    <div style="display: flex;justify-content: space-around;margin-top: 20px">
          <div>
          <img  src="../../public/1131.png"/>
          <a target="_blank" class="beian" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010602100511">京公网安备11010602100511号</a>
          </div>

          <a target="_blank" class="beian" href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2020035410号</a>

          <span class="beian">增值电信业务经营许可证：京B2-202003293 B2-20210212</span>

          <span class="beian">Copyright © 2019 - 2021 北京五凤城科技有限公司版权所有</span>

    </div>
  </div>
</template>
<script>
  export default {
    name: 'bottoms',
    data () {
      return {}
    },
    methods: {},
  }
</script>
<style scoped>
  .bottoms {
    width: 100%;
    height: 275px;
    background: #363a3e;
  }

  .order {
    width: 210px;
    height: 18px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #d3d3d3;
    line-height: 30px;
  }

  .dizhi {
    width: 198px;
    height: 12px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #d3d3d3;
    line-height: 30px;
  }

  .phone {

    height: 9px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #d3d3d3;
    line-height: 30px;
  }

  .beian {

    font-size: 12px;

    color: #d3d3d3;

  }

  .ze {

    font-size: 12px;

    color: #d3d3d3;

  }

  .ban {
    width: 33%;
    height: 13px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #d3d3d3;
    line-height: 30px;
    margin-right: 9%;

  }

  #tup {
    width: 126px;
    height: 13px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }

  #tups {
    width: 126px;
    height: 13px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }

  .orderf {
    margin-left: 20%;
    margin-top: 4%;
  }

  .fenge {
    width: 100%;
    height: 1px;
    background: #504d5f;
  }

  .tu {
    margin-top: 4%;
    margin-left: 30%;
  }

  .you {
    margin-left: 4%;
  }

  .di {
    margin-top: 1%;
    position: relative;
  }

</style>
<style type="text/css">
  body {
    margin: 0px;
  }

  .zhezhao {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0px;
    top: 0px;
    background: #000;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }

  .tankuang {
    position: relative;
    background: #fff;
    width: 50%;
    height: 80%;
    border-radius: 5px;
    margin: 5% auto;
  }

  #header {
    height: 40px;
  }

  #header-right {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    background: red;
    color: #fff;
    right: 5px;
    top: 5px;
    text-align: center;
  }
</style>
