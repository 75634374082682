<template>
  <div class="details" style="overflow-x: hidden">
    <Hear />
    <!-- https://oss.duijh.cn/upload/test/dsi297893524sqvz.png -->
    <img
      style="height: 100%; width: 100%; display: block; margin: 20px auto 0 auto;"

      
    
      src="https://oss.duijh.cn/upload/test/wbv989713365bepi.jpg"
      
    />
    <!-- <img
      style="height: 100%; width: 100%; display: block; margin: 0 auto"
      src="../../public/xiang.png"
    /> -->

    <img
      style="height: 100%; width: 100%; display: block; margin: 0 auto"
      src="https://oss.duijh.cn/upload/test/oxd973862628qbyz.png"
    />
    <bottom />
  </div>
</template>
<!-- <script type='text/javascript' src='https://webchat.7moor.com/javascripts/7moorInit.js?accessId=8bb96590-4dfa-11ee-94a2-c33ec2b31024&autoShow=true&language=ZHCN' async='async'/> -->

<script>
import Hear from "@/components/Heard.vue";
import Bottom from "../components/bottom.vue";

export default {
  name: "about",
  components: {
    Hear,

    Bottom,
  },
};
</script>
