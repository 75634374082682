import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import about from '../views/About'
import details from '../views/details'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Home',
    component: Home,
    // meta: { hidden: true,title:"兑金行 黄金回收首饰回收"}
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: about
  },
  {
    path: '/details',
    name: 'Details',
    component: details
  }
]

const router = new VueRouter({
 mode: "history",
  routes
})

export default router
