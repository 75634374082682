<template>

  <div class="car" style="margin-top: 99px">
    <div class="block">
      <!-- 轮播图 -->
      <el-carousel :height= 'heigh'>
        <el-carousel-item v-for="item in banner" :key="item">
          <img id="oImg" ref="img_ref" :src=item class="carimg" @load="img_load" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>


export default {
  name: "car",
  data() {
    return {
      heigh: "",
      banner: [],
    };
  },

  created() {
    var _this = this;
    this.$axios
      .get(" https://duijh.cn:3001/jeecg-boot/duijh/officialWebsite/list")
      .then(function (resp) {
        if (resp.data.code == "200") {
          var da = resp.data.result.records;
          for (let index = 0; index < da.length; index++) {
            const element = da[index];
            if(element.designation == 'PCbanner'){
              _this.banner = element.imgpathone.split(",");
       
            }
            
          }

        } else {
          // this.$message.error("系统异常情稍后在试");
        }
      });

   
  },
  methods: {
    img_load() {
      console.log(this.$refs.img_ref[0].height, "img_height");
      var img_height = this.$refs.img_ref[0].height + "";
      console.log(img_height.length == 3);

      if ((window.outerWidth < 1232) & (window.outerHeight < 900)) {
        this.heigh = "500px";
      } else {
        this.heigh = img_height + "px";
      }
      // console.log(this.$refs.img_ref[0].width, "img_width");
    },
  },
};
</script>


<style>
.carimg {
  width: 100%;
  height: auto;
}
</style>
