import Vue from 'vue'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)
import App from './App.vue'
import router from './router'
import './plugins/element.js'
const iPicker = require("new-ipicker");
import VueAxios from 'vue-axios';
import axios from 'axios';
Vue.prototype.$axios = axios;
Vue.config.productionTip = false
import animated from 'animate.css/animate.compat.css'//引入动画
Vue.use(animated) 

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
