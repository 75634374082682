<template>
 <div>
      <!--    扫码-->
    <el-popover ref="reference" placement="left" trigger="hover" width="150px">
      <img
        src="../../public/WechatIMG15cc75e7b2f15a688d7ff4b905dfb2ce.png"
        style="display: block; margin: 0 auto; width: 70%"
      />
      <span style="text-align: center; display: block; margin-top: 3px"
        >兑鑫行小程序</span
      >
    </el-popover>
        <!--电话-->
    <el-popover ref="nce" placement="left" trigger="hover" width="150px">
      <!-- <span style="text-align: center; display: block">热线电话:</span>
      <span style="text-align: center; display: block">400-009-3003</span>
      <div style="height: 3px"></div>
      <span style="text-align: center; display: block">工作时间:</span>
      <span style="text-align: center; display: block"> 9:00-18:00</span> -->
       <img
        src="https://oss.duijh.cn/upload/test/olg210250811zdtd.png"
        style="display: block; margin: 0 auto; width: 70%"
      />
      <span style="text-align: center; display: block; margin-top: 3px"
        >打开京东app扫一扫</span
      >
    </el-popover>

    <!--客服-->
    <el-popover ref="kef" placement="left" trigger="hover" width="150px">
      <img
        src="../../public/WechatIMG5c7d0622180e6203b2d34c5e8408e23d.png"
        style="display: block; margin: auto auto auto; width: 70%"
      />
      <span style="text-align: center; display: block; margin-top: 3px"
        >扫码加客服微信</span
      >
    </el-popover>

  <div
    ref="all"
    class="sid_ce"
    style="
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    "
  >
 


    <div style="width:20px"></div>
    <div class="kua" v-popover:reference>
      <img
        style="margin: auto auto auto; display: block;"
        src="../../public/BrowserPreview_tmp.gif"
      />
      <div>
      <span class="jzit" style="color: #C77A0E">扫码</span> 

      <span class="jzit" style="color: #C77A0E;margin-top:1px">卖金</span> 
      </div>
    </div>
    <div class="f"></div>

    <!-- <div
      class="kua"
      v-popover:nce
      @mouseenter="enter(1, mar1)"
      @mouseleave="leave(1, mar1)"
    >
      <img
        id="11"
        v-if="mar1 == 1"
        style="margin: 0 auto; display: block;"
        src="https://oss.duijh.cn/upload/test/xzt560955900oxdt.png"
      />
      <img
        v-if="pd == 1"
        style="margin: 0 auto; display: block;"
        src="https://oss.duijh.cn/upload/test/lam578753077meye.png"
      />
      <div>
      <span class="jzit" id="1">旧金</span> 

      <span class="jzit" id="k1" style="margin-top:1px">换新</span> 
      </div>
    </div>
    <div class="f"></div> -->

    <div
      class="kua"
      @click="customer"
      @mouseenter="enter(2, mar2)"
      @mouseleave="leave(2, mar2)"
    >
      <img
        v-if="mar2 == 1"
        style="margin: auto auto auto; display: block;"
        src="../../public/780.png"
      />
      <img
        v-if="pd == 2"
        style="margin: auto auto auto; display: block;"
        src="https://oss.duijh.cn/upload/test/nup144093676atah.png"
      />
      <div>
      <span class="jzit" id="2">在线</span> 

      <span class="jzit" id="k2" style="margin-top:1px">客服</span> 
      </div>
    </div>
    <div class="f"></div>

    <div
      class="kua"
      v-popover:kef
      @mouseenter="enter(3, mar3)"
      @mouseleave="leave(3, mar3)"
    >
      <img
        v-if="mar3 == 1"
        style="margin: auto auto auto; display: block;"
        src="../../public/961.png"
      />
      <img
        v-if="pd == 3"
        style="margin: auto auto auto; display: block;"
        src="https://oss.duijh.cn/upload/test/mtt146183070tfiq.png"
      />
      
       <div >
      <span class="jzit" id="3">微信</span> 

      <span class="jzit" id="k3" style="margin-top:1px">客服</span> 
      </div>
    </div>
    <div class="f"></div>

    <div
      class="kua"
      @click="zhid()"
      @mouseenter="enter(4, mar4)"
      @mouseleave="leave(4, mar4)"
    >
      <img
        v-if="mar4 == 1"
        style="margin: auto auto auto; display: block;"
        src="../../public/640.png"
      />
      <img
        v-if="pd == 4"
        style="margin: auto auto auto; display: block;"
        src="https://oss.duijh.cn/upload/test/suz304897300kqnr.png"
      />
      <p class="jzit" id="4">置顶</p>
    </div>
    <div style="width:20px"></div>
  </div>
   </div>
</template>
<script>
export default {
  name: "sid_ce",
  data() {
    return {
      four: "",
      mar: 1,
      pd: "",
      mar1: "1",
      mar2: "1",
      mar3: "1",
      mar4: "1",
      oldScrollTop: 0, //记录上一次滚动结束后的滚动距离
      scrollTop: 0, // 记录当前的滚动距离
    };
  },
  watch: {
    scrollTop(newValue, oldValue) {
      setTimeout(() => {
        if (newValue == window.scrollY) {
          //延时执行后当newValue等于window.scrollY，代表滚动结束
          console.log("滚动结束");
          this.oldScrollTop = newValue; //每次滚动结束后都要给oldScrollTop赋值
          this.$refs.all.style.right = "0";
          this.$refs.all.style.transition = "all 200ms";
        }
      }, 20); //必须使用延时器，否则每次newValue和window.scrollY都相等，无法判断，20ms刚好大于watch的侦听周期，故延时20ms
      if (this.oldScrollTop == oldValue) {
        //每次滚动开始时oldScrollTop与oldValue相等
        console.log("滚动开始");
        this.$refs.all.style.right = "-70px";
        this.$refs.all.style.transition = "all 500ms";
      }
    },
  },

  mounted() {
    this.handleScroll();
  },
  destroyed() {
    window.removeEventListener("scroll", this.throttleLoad);
  },
  beforeDestroy() {
    window.removeEventListener("scroll"); //离开当前组件别忘记移除事件监听哦
  },
  methods: {
    handleScroll() {
      window.addEventListener("scroll", () => {
        this.scrollTop = window.scrollY;
      });
    },

    throttleLoad() {},
    enter(id, oid) {
      console.log("入");

      this.pd = id;
      if (id == "1") {
        this.mar1 = 2;
      } else if (id == "2") {
        this.mar2 = 2;
      } else if (id == "3") {
        this.mar3 = 2;
      } else if (id == "4") {
        this.mar4 = 2;
      }

      document.getElementById(id).style.color = "#C77A0E";
      document.getElementById('k'+id).style.color = "#C77A0E";

    },
    leave(id, oid) {
      document.getElementById(id).style.color = "black";
      
      this.pd = id;
      console.log("出");
      this.pd = 0;
      if (id == "1") {
        this.mar1 = 1;
        document.getElementById('k'+id).style.color = "black";
      } else if (id == "2") {
        this.mar2 = 1;
        document.getElementById('k'+id).style.color = "black";
      } else if (id == "3") {
        this.mar3 = 1;
        document.getElementById('k'+id).style.color = "black";
      } else if (id == "4") {
        this.mar4 = 1;
      }
    },
    customer() {
      // this.$router.push({name: 'About'});
      // window.location.href =
      //   "https://085670.kefu.easemob.com/webim/im.html?configId=97eba1e1-153c-4734-b6e4-5b3b3d1ae9a2";
      qimoChatClick();

        
    },
    zhid() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style>
.sid_ce {
  top: 5%;
  z-index: 9999;
  position: fixed;
  right: 0px;
  width: 67px;
  height: 600px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

.f {
  width: 67px;
  height: 1px;
  background: #2f2d3a;
  opacity: 0.1;
}

.kua {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/*被点击的*/
.zit {
  width: 30px;
  height: 26px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ff2500;
  margin: 0 auto;
  display: block;
  margin-top: 13px;
}

/*没有被点击的*/
.jzit {
  
  
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #181717;
  margin: 0 auto;
  display: block;
  margin-top: 13px;
}

.jiant {
  width: 139px;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
</style>
