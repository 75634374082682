<template>
  <div class="videoPlayer">
    <img
      class="PictureContent"
      src="./img/123456.png"
      id="bgimg"
      @click="fnbgimg"
    />
    <div class="org_parent" id="allvideo">
      <video ref="video"  class="getPlayer">
        <source
          src="https://oss.duijh.cn/upload/test/pio697560298seqa.mp4"
          type="video/mp4"
        />
        <source
          src="http://nettuts.s3.amazonaws.com/763_sammyJSIntro/trailer_test.ogg"
          type="video/ogg"
        />
      </video>
      <div ref="videoControls" class="videoControls">
        <div style="margin-left: 9px">
          <i
            style="color: #ffffff"
            ref="playBtn"
            class="el-icon-video-play"
          ></i>
        </div>
        <!--  中间的进度条 -->
        <div ref="progressWrap" class="progressWrap">
          <div ref="playProgress" class="progressWrap"></div>
        </div>
        <span style="color: #ffffff" ref="showProgress" class="showProgress">00:00:00</span>
        <div style="margin-right: 9px">
          <i
            style="color: #ffffff"
            ref="fullScreenBtn"
            class="el-icon-full-screen"
          ></i>
        </div>
      </div>
    </div>
    <img
      class="picture-move"
      src="https://oss.duijh.cn/upload/test/agw848826075kwkw.png"
    />
  </div>
</template>
<script>
// var video = document.getElementById("video");
// var videoControls = document.getElementById("videoControls");
// // var videoContainer = document.getElementById("videoContainer");
// var controls = document.getElementById("video_controls");
// var playBtn = document.getElementById("playBtn");
// var fullScreenBtn = document.getElementById("fullScreenBtn");
// var progressWrap = document.getElementById("progressWrap");
// var playProgress = document.getElementById("playProgress");
// var bgimg = document.getElementById("bgimg");
// var fullScreenFlag = false;
// var progressFlag;
export default {
  name: "myvideo",
  data() {
    return {
      progressFlag: "",
      fullScreenFlag :false,

    };
  },

  created() {
    this.$nextTick(() => {
      var video = this.$refs.video;
      this.$refs.video.removeAttribute("controls");
      this.bindEvent(video, "loadeddata", this.initControls);
      this.bindEvent(bgimg, "click", bgimg);
      this.operateControls();
    });
  },
  methods: {
    initControls: function () {
      this.showHideControls();
    },
    showHideControls: function () {
      var video = this.$refs.video;
      var videoControls = this.$refs.videoControls;
      this.bindEvent(video, "mouseover", this.showControls);
      this.bindEvent(videoControls, "mouseover", this.showControls);
      this.bindEvent(video, "mouseout", this.hideControls);
      this.bindEvent(videoControls, "mouseout", this.hideControls);
    },
    operateControls: function () {
      var playBtn = this.$refs.playBtn;
      var video = this.$refs.video;
      var fullScreenBtn = this.$refs.fullScreenBtn;
      var progressWrap = this.$refs.progressWrap;
      this.bindEvent(playBtn, "click", this.play);
      this.bindEvent(video, "click", this.play);
      this.bindEvent(fullScreenBtn, "click", this.fullScreen);
      this.bindEvent(progressWrap, "mousedown", this.videoSeek);
    },
    // 原生的JavaScript事件绑定函数
    bindEvent(ele, eventName, func) {
      if (window.addEventListener) {
        ele.addEventListener(eventName, func);
      } else {
        ele.attachEvent("on" + eventName, func);
      }
    },
    // 显示video的控制面板
    showControls() {
      var videoControls = this.$refs.videoControls;
      videoControls.style.opacity = 1;
    },
    // 隐藏video的控制面板
    hideControls() {
      var videoControls = this.$refs.videoControls;
      // 为了让控制面板一直出现，我把videoControls.style.opacity的值改为1
      videoControls.style.opacity = 1;
    },
    // 控制video的播放
    play() {
      var video = this.$refs.video;
      if (video.paused || video.ended) {
        if (video.ended) {
          video.currentTime = 0;
        }
        video.play();
        var playBtn = this.$refs.playBtn;
        playBtn.setAttribute("class", "el-icon-video-play");
        this.progressFlag = setInterval(this.getProgress, 60);
      } else {
        video.pause();
        var playBtn = this.$refs.playBtn;
        playBtn.setAttribute("class", "el-icon-video-pause");
        clearInterval(this.progressFlag);
      }
    },
    // 控制video是否全屏
    fullScreen() {
      var video = this.$refs.video;
      if (this.fullScreenFlag) {
        video.webkitCancelFullScreen();
      } else {
        video.webkitRequestFullscreen();
      }
    },

    // video的播放条
    getProgress() {
      var video = this.$refs.video;
      var progressWrap = this.$refs.progressWrap;
      var playProgress = this.$refs.playProgress;
      var percent = video.currentTime / video.duration;
      var showProgress = this.$refs.showProgress
      playProgress.style.width = percent * progressWrap.offsetWidth - 2 + "px";
      // showProgress.innerHTML = (percent * 100).toFixed(1) + "%";
      // console.log(Math.floor(video.currentTime), "2323232323232");
      var da = this.formatTime(Math.floor(video.currentTime) * 1000);
      console.log(da);
      showProgress.innerHTML = da;

      // var show = document.getElementById("show")
      // show.setAttribute("percentage",(percent * 100).toFixed(1))
    },
    // 鼠标在播放条上点击时进行捕获并进行处理
    videoSeek(e) {
      if (video.paused || video.ended) {
        play();
        enhanceVideoSeek(e);
      } else {
        enhanceVideoSeek(e);
      }
    },

    formatTime(millisecond) {
      let seconds = Math.round(millisecond / 1000);
      let result = [];
      let count = 2;
      while (count >= 0) {
        let current = Math.floor(seconds / 60 ** count);
        result.push(current);
        seconds -= current * 60 ** count;
        --count;
      }
      return result.map((item) => (item <= 9 ? `0${item}` : item)).join(":");
    },

    enhanceVideoSeek(e) {
      console.log(e);
      clearInterval(this.progressFlag);
      var length = e.pageX - progressWrap.offsetLeft;
      var percent = length / progressWrap.offsetWidth;
      var playProgress = this.$refs.playProgress;
      playProgress.style.width = percent * progressWrap.offsetWidth - 2 + "px";
      video.currentTime = percent * video.duration;
      this.progressFlag = setInterval(this.getProgress, 60);
    },

    //图片点击
    fnbgimg() {
      document.getElementById("bgimg").style.display = "none";
      document.getElementById("allvideo").style.display = "inline";
      this.play();
    },
  },
};
</script>
<style>

.videoPlayer {
  /* border: 1px solid #000; */
}
.getPlayer{

  width: 594px;
  position: relative;
  bottom: 61px;
}
.PictureContent {
  width: 595px;
  position: absolute;
  bottom: 4px;
  height: 376px;
  margin-left: 29px;
  z-index: 9;
  /* position: absolute; */
}

.org_parent {
  /* position: absolute; */
  display: none;
  width: 594px;
  position: absolute;
  bottom: 37px;
  height: 273px;
  margin-left: 30px;
  z-index: 9;
}

.picture-move {
  height: 100px;
  width: 650px;
  /* position: relative; */
  top: 176px;
}

.videoControls {
  background-color: rgb(43 51 63 / 70%);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* position: relative; */
  top: -64px
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
}

.progressWrap {
  width: 70%;
  background-color: #6f7a8b;
  height: 1px;
  cursor: pointer;
}

.playProgress {
  background-color: #ffffff;
  width: 0px;
  height: 2px;
}

.showProgress {
  background-color: ;
  font-weight: 600;
  font-size: 13px;
  line-height: 25px;
}

.bofang {
  border: none;
  background-color: transparent;
  outline: none;
}

</style>

<style>
@media screen and (max-width: 1440px) {
.videoPlayer {
  /* border: 1px solid #000; */
}
.getPlayer{

  width: 493px;
  position: relative;
  bottom: 2px;
}
.PictureContent {
  width: 493px;
  position: absolute;
  bottom: 4px;
  height: 313px;
  margin-left: 26px;
  z-index: 9;
  /* position: absolute; */
}

.org_parent {
  /* position: absolute; */
  display: none;
  width: 493px;
  position: absolute;
  bottom: 37px;
  height: 273px;
  margin-left: 25px;
  z-index: 9;
}

.picture-move {
  height: 100px;
  width: 541px;
  /* position: relative; */
  top: 176px;
}

.videoControls {
  background-color: rgb(43 51 63 / 70%);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* position: relative; */
  top: -5px;
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
}

.progressWrap {
  width: 70%;
  background-color: #6f7a8b;
  height: 1px;
  cursor: pointer;
}

.playProgress {
  background-color: #ffffff;
  width: 0px;
  height: 2px;
}

.showProgress {
  background-color: ;
  font-weight: 600;
  font-size: 13px;
  line-height: 25px;
}

.bofang {
  border: none;
  background-color: transparent;
  outline: none;
}
}
</style>


<style>
@media screen and (min-width: 960px) and (max-width: 1366px) {
.videoPlayer {
  /* border: 1px solid #000; */
}
.getPlayer{

  width: 438px;
  position: relative;
  bottom: 61px;
}
.PictureContent {
  width: 437px;
  position: absolute;
  bottom: 4px;
  height: 278px;
  margin-left: 23px;
  z-index: 9;
  /* position: absolute; */
}

.org_parent {
  /* position: absolute; */
  display: none;
  width: 438px;
  position: absolute;
  bottom: -53px;
  height: 273px;
  margin-left: 22px;
  z-index: 9;
}

.picture-move {
  height: 100px;
  width: 480px;
  /* position: relative; */
  top: 176px;
}

.videoControls {
  background-color: rgb(43 51 63 / 70%);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* position: relative; */
  top: -64px
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
}

.progressWrap {
  width: 70%;
  background-color: #6f7a8b;
  height: 1px;
  cursor: pointer;
}

.playProgress {
  background-color: #ffffff;
  width: 0px;
  height: 2px;
}

.showProgress {
  background-color: ;
  font-weight: 600;
  font-size: 13px;
  line-height: 25px;
}

.bofang {
  border: none;
  background-color: transparent;
  outline: none;
}
}
</style>
