<template>
  <div class="about" style="overflow-x: hidden">
    <Hear />

    <!-- <div class="zx_header">{{ info[ind].headline }}</div>
    <p class="zx_nei" style="text-indent: 2em">{{ info[ind].connectone }}</p> -->
    <div class="zx_header">{{ind == 0 ? hyyd0.headline :  hyyd1.headline}}</div>
    <p class="zx_nei" style="text-indent: 2em">{{ ind == 0 ? hyyd0.connectone : hyyd1.connectone }}</p>

    <div style="">
      <div
        style="
          margin-top: 130px;
          margin-left: 8%;
          width: 200px;
          height: 24px;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 24px;
        "
      >
        相关阅读
      </div>

      <div @click="ztop(0)" >
        <div style="margin-left: 8%; margin-top: 40px">
          <el-row>
            <el-col :span="10">
              <img style="width: 337px" :src="hyyd0.imgpathone" />
            </el-col>

            <el-col :span="12" >
              <div style="float: right; margin-right: 7%">
                <!-- <p class="zx_tabs">{{ info[0].headline }}</p> -->
                <p class="zx_tabs">{{hyyd0.headline}}</p>
                <p
                  class="zx_tabd"
                  style="
                    overflow: hidden;
                    height: 2%;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                  "
                >
                  {{hyyd0.connectone}}
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div @click="ztop(1)">
        <div style="margin-left: 8%; margin-top: 40px">
          <el-row>
            <el-col :span="10">
              <img :src="hyyd1.imgpathone" style="width: 337px" />
            </el-col>
            <el-col :span="12">
              <div style="float: right; margin-right: 7%">
                <!-- <p class="zx_tabs">{{ info[1].headline }}</p> -->
                <p class="zx_tabs">{{hyyd1.headline }}</p>
                <p
                  class="zx_tabd"
                  style="
                    overflow: hidden;
                    height: 2%;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  "
                >
                  <!-- {{ info[1].connectone }} -->
                  {{hyyd1.connectone}}
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div @click="ztop(2)">
        <div style="margin-left: 8%; margin-top: 40px;margin-bottom: 20px">
          <el-row>
            <el-col :span="10">
              <img :src="hyyd2.imgpathone" style="width: 337px" />
            </el-col>
            <el-col :span="12">
              <div style="float: right; margin-right: 7%">
                <p class="zx_tabs">{{ hyyd2.headline }}</p>
                <p
                  class="zx_tabd"
                  style="
                    overflow: hidden;
                    height: 2%;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  "
                >
                  {{ hyyd2.connectone }}
                </p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <Bottom />
  </div>
</template>
<script>
import Hear from "@/components/Heard.vue";
import Bottom from "../components/bottom.vue";

export default {
  name: "about",
  components: {
    Hear,

    Bottom,
  },
  data() {
    return {
      ind: 0,
      info:[
      ],
      hyyd0:{},
      hyyd1:{},
      hyyd2:{},
      mjx:{},
      top: [
        "自古以来，黄金价格总是与经济发展水平相关甚深，在国民购买力不断提升的今天，越来越多的黄金饰品出现在人们的生活中。而随着黄金各品牌的款式更新上市，部分过时黄金首饰自然就被人们闲置了，这就催生了二手黄金回收这一行业的发展。\n" +
          "\n" +
          "近年来，黄金回收价格一直是人们比较关注的话题，而行业乱象丛生也成为黄金回收从业者不可忽视的问题，行业乱象的相关报道更是层出不穷。《新闻晚高峰》记者曾做过相关的披露报道，其以“黄金变现者”身份走访的回收地点包含银行、商场、金楼、典当行和线下回收店。结果，一条47.3克的黄金项链，在五家店里竟然称出了5个不同的重量。其中，有一家店竟称出了4个不同的重量，按当时的金价计算前后4次称重后回收价格相差4550元之多。\n" +
          "\n" +
          "从以上的报道我们不难看出，黄金市场买进容易卖出难，尤其是黄金变现远比变现需求者想象的要复杂。如不考虑投资的情况从纯粹变现角度来做比较，通过黄金回收店进行交易的“水很深”。首先是黄金折现价格乱，虽说变现需求者每天可查询黄金的即时价格，但不同的线下回收店面回收价格差异非常明显。再就是严重的“缺斤短两”，采访中不仅出现了五家店给出五个数据的乱象，更有回购店面五分钟内称出了32.92克到47.86克不等的重量，前后价差4550元之大，等于整整差了11克多的黄金。一旦消费者糊里糊涂地出售黄金，受到的损失可想而知。\n" +
          "\n" +
          "强势出击，打造黄金回收良性发展\n" +
          "\n" +
          "在这样的黄金回收行业现状下，北京工美集团（国企）旗下黄金回收平台“兑鑫行”（微信小程序搜索“兑鑫行”）借势出击，针对有黄金回收需求的消费者，打造出一个健康规范的黄金回收业态。\n" +
          "\n" +
          "不同于线下门店的小作坊式经营模式，兑鑫行采用的是“互联网+黄金回收”的服务模式。从黄金估价到变现打款全程线上服务，用户最关心的黄金回收价格则是同步上海黄金交易所黄金实时价格，整个流程透明可信，有据可依。\n" +
          "\n" +
          "兑鑫行自上线以来依靠着工美集团搭建了专业的运营团队、成熟的服务体系，协同安全可靠的合作机构，利用互联网的技术支持，将互联网与黄金回收业务紧密结合，合理的解决了黄金回收行业无标杆、回收价格不透明、质检检测不专业等行业乱象,为广大持金用户解决了买金易卖金难的问题。\n" +
          "\n" +
          "目前，“兑鑫行”已正式上线运营，作为工美集团旗下黄金回收平台，肩负着提升黄金回收业态良性发展的重大责任。未来兑鑫行将会与工美集团勠力齐心以安全、快速、高价为发展方向，合规运营、稳健发展将兑金行打造成亿万民众最信赖的黄金回收平台。 ",

        "二十一世纪，随着互联网时代的发展成熟产生了海量的信息，而信息革命的深入推进让我们每个人走向了大数据时代。虽然互联网深层次拓展了发展空间，但也带来了隐私保护的风险挑战。注册一个会员、发一个朋友圈、连一次无线网、订一份外卖等等，我们的个人信息就可能存在泄露风险。网络信息安全问题已成为当下各行业亟待解决的新课题。\n" +
          "\n" +
          "电影《天下无贼》中曾有这样一句台词，“二十一世纪什么最贵，人才”，但兑鑫行认为二十一世纪“信息”同样“贵”，但信息的“贵”不等同于人才的明码标价，它关系的范围更加广阔，从国家安全到企业的发展、消费者生产生活服务的提升，都与信息密集相连，可以值得“无价”二字作为评价。\n" +
          "\n" +
          "信息作为一种资源，它的普遍性、共享性、增值性、可处理性和多效用性，使其对国家、国民及各行业具有特别重要的意义。而在各行业中，消费者的个人信息难免会被企业作为优化服务为前提进而使用，这时信息安全就成为了公众最为在意的聚焦点。\n" +
          "\n" +
          "兑鑫行黄金回收平台在成立之初，就明确表示：“黄金回收企业应着重聚焦信息安全，只有做到信息安全才能提升消费者安全感，消费者有了信任与依赖，才是一个企业持续发展的前提。”\n" +
          "　\n" +
          "“深化”信息安全意识，从兑鑫行做起\n" +
          "\n" +
          "\n" +
          "为了能让用户切身的享受互联网服务的人性化感受互联网+带来的便捷，兑鑫行黄金回收从下单、问询、运输、质检、收款全流程采取线上服务。\n" +
          "\n" +
          "·针对消费者关心的黄金回收价格问题，兑金行在小程序中接入了上海黄金交易所（工作日期间）和粤鹏金（非工作日）两个国内权威黄金交易平台实时金价，这一信息功能中兑金行有效的去除了“中间商环节”，避免了长期以来回收方与消费者在前后端获取的黄金价格不一致的顽疾；\n" +
          "\n" +
          "·运输环节兑金行则选用国内口碑与实力俱佳的顺丰速运为合作方，全流程实时监控并由兑金行方进行保价，为消费者的财产加上了一把安全锁；\n" +
          "\n" +
          "·质检环节兑金行指定国内最具权威的贵金属质检机构国家首饰质量监督检验中心（National Jewellery Quality Supervision and Inspection Center，简称NJQSIC）为官方唯一质检合作机构。消费者的黄金制品到达国首中心后，即用先进的仪器设备和检验报告为兑金行消费者的黄金检验检测提供了有力的技术保证和行业规范化示范。目前，兑金行也是少数几家能与国家首饰质量监督检验中心合作的线上黄金回收平台；\n" +
          "\n" +
          "·消费者确认质检报告后，兑金行后台程序立即触发打款程序。整个流程中兑金行对所有用户的个人信息实行保密政策，并采用同卡进出的原则；\n" +
          "\n" +
          "·兑金行所有涉及IT系统的开发工作，都是由专业技术工程师独立完成，绝不采用其他厂商系统，这样才能从底层架构上做到安全上的自主可控。在服务器安全防护方面，兑金行配置了防火墙，能够有效防范跨站的脚本攻击、DDOS攻击，及防范主机密码破解等，以此保证消费者数据安全。\n" +
          "\n" +
          "通过以上严格、科学的信息安全控制体系兑金行才有可能做到保证平台消费者信息安全的可能性。\n" +
          "\n" +
          "兑金行利用互联网技术去除中间环节，做到了消费者直达精炼厂的“一站式互联网”服务模式，未来，兑金行将会持续优化安全防护网、从技术上打造安全数据链、同时在理念上提高安全意识，全方位协同用力深耕“信息安全”，打赢黄金回收行业信息安全保卫战。\n",
        "摘要--“买金容易卖金难”的问题长期凸显，作为北京工美集团有限责任公司旗下的互联网黄金回收平台，兑金行打通物流—质检—精炼厂等重要环节，实现了物流、信息流、资金流的有效衔接为消费者撑起一把保护伞。\n" +
          "\n" +
          "2021年1月19日中国信息产业网给出了一份名为《2020年中国黄金饰品市场规模、发展中存在问题及趋势分析》的数据报告，数据显示：“随着我国经济的不断发展、居民消费升级，黄金饰品作为能够满足精神需求的产品日益受到消费者的喜爱。预计我国黄金饰品市场规模在2024年可达到4200亿元左右”。\n" +
          "\n" +
          "作为一个黄金消费大国，黄金售卖伴随着黄金回收业务的产生，同样备受关注。在2020年的特殊环境下，黄金市场交易活跃，尤其是在春节前投资者对黄金的关注不断攀升，中国黄金协会数据显示，2020年三季度，金条金币销量环比增长高达66.73%。其中线上黄金回购的需求增长显著，据相关数据显示，2021年1月份，某平台的黄金回购业务成交额同比去年增长高达300%。\n" +
          "\n" +
          "对于黄金投资者而言，虽然卖金变现的渠道很多，从街边店、典当行到金店、银行等，但“买金容易卖金难”的问题却长期凸显。一直以来，黄金投资者对于靠谱、稳定、高效的黄金回购平台呼声很高。\n" +
          "\n" +
          "基于此，作为北京工美集团有限责任公司旗下的互联网黄金回收平台，兑金行更加坚定了运营黄金回收业务发展的决策。\n" +
          "\n" +
          "同时，兑金行借助于互联网技术，推出了官方网站（http://www.duijin.net/#/）及微信小程序（兑金行黄金回收首饰回收）线上应用服务，并凝聚了一批来自于珠宝首饰行业、金融行业、互联网行业，具有十年以上从业经验的核心成员。\n" +
          "\n" +
          "在互联网服务理念不断深入的前提下，兑金行将“互联网+黄金回收业务”智能的融为一体。在全线上服务流程里，与顺丰速运达成协作，让用户不仅可以在线上“买金”还可以通过线上“卖金”，打破了地域和时间的局限性。同时，兑金行为每克实物黄金购买全额物流运输保险，保障寄送过程中的安全，而消费者可在这一环节通过平台触发的短信实时监控实物黄金的位置，减少用户在体验过程中产生的不安全感。\n" +
          "\n" +
          "在质检这一环节兑金行更是做到了行业中的翘楚地位，目前兑金行已与国家首饰质量监督检验中心（National Jewellery Quality Supervision and Inspection Center，简称NJQSIC）（以下简称“国首”）正式合作，国首中心是国家认监委（CNCA）依法授权的国家级质检机构，并经中国合格评定国家认可委员会认可；国首中心还是全国首饰标准化技术委员会（ISO/TC174）秘书处单位，多次主持制定、修订国家标准和行业标准。国首中心还面向社会提供委托检验服务，并承担国家质监、工商、公安、司法等政府部门的监督检验、仲裁检验。其作为国家级质检机构公信力首屈一指，是兑金行法定授权的唯一定点检测单位，可为体验卖金流程的消费者出具国家级质量检测证书。\n" +
          "\n" +
          "随着互联网科技的深入普及，通过互联网渠道进行黄金回收将成为黄金回收的主流方式，而线上服务的最大特色就是让消费者足不出户即可体验卖金的便利，在兑金行体验卖金的消费者除了可以享受“上门服务”“国家级检质检服务”外，确认质检报告后还可享受到卖金款项“同卡进出”的便捷服务。\n" +
          "\n" +
          "在黄金回收业务乱象不断地荆棘丛中，兑金行试图打破每一个不可能让其变成合理的“有可能”。在打通物流—质检—精炼厂等重要环节后，真正实现了物流、信息流、资金流的有效衔接为消费者撑起一把保护伞。实实在在的解决了用户黄金变现难、变现慢的问题，未来，兑金行将为用户提供更加优质的黄金回收体验，让黄金以更加灵活自由的形态存在于人们的生活。\n" +
          "\n",
      ],
      tab: [
        "兑金行践行规范运营",
        "聚焦黄金回收业务信息安全",
        "兑金行为消费者撑起保护伞",
      ],
    };
  },
  created() {
    var _this = this
    console.log(this.$route.query.id);
    this.ind = this.$route.query.id;

    // 获取页面所需图片数据
    this.$axios
      .get(' https://duijh.cn:3001/jeecg-boot/duijh/officialWebsite/list')
      .then(function (resp) {
        if (resp.data.code == '200') {
          var da = resp.data.result.records
          debugger
          for(let i = 0;i<10;i++){
            if(da[i].id == 7){
              _this.hyyd0 = da[i]
            }else if(da[i].id == 9){
              _this.hyyd1 = da[i]
            }else if(da[i].id == 10){
              _this.hyyd2 = da[i]
            }
          }

          // _this.info.push(da[8])
          // _this.info.push(da[6])
          // _this.info.push(da[7])
          // console.log(_this.info)
        } else {
          // this.$message.error('系统异常情稍后在试')
        }
      })


  },
  methods: {
    ztop(id) {
      this.ind = id;

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.zx_tabs {

  height: 24px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #2f2d3a;
}
.zx_tabd {
  width: 106%;
  height: 34px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #999999;
  line-height: 20px;
  margin-top: 120px;
}
.zx_nei {
  width: 85%;
  height: 80%;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #2f2d3a;
  line-height: 40px;
  display: block;
  margin: 0 auto;
  margin-top: 131px;
}
.zx_header {
  display: inline;
  text-align: center;
  height: 48px;
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 24px;

  margin: 0 auto;
  display: block;
  margin-top: 138px;
}
</style>
